/**
 *
 * @author fuyg
 * @date  2020-06-04
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './YearNews.module.scss'
import { fetchNews } from 'src/pages/home/store/slices/news'
import { RootState } from 'src/pages/home/store/rootReducer'
import { RecentNewsItem } from 'src/pages/home/components/RecentNewsItem'
import { fetchAppearYears } from 'src/pages/home/store/slices/newsAppearYears'
import { Select, Tabs } from 'antd'
const { Option } = Select
const { TabPane } = Tabs
import moment from 'moment'

export interface YearNewsProps {
  year: string
}

function YearNews(): ReactElement {
  // 获取数据
  const dispatch = useDispatch()
  const [year, setYear] = useState('')
  const [activeKey, setActiveKey] = useState<string>('1')
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  useEffect(() => {
    dispatch(fetchAppearYears())
  }, [])

  useEffect(() => {
    if (year) {
      const startStamp = moment()
        .year(Number(year))
        .tz('America/New_York')
        .startOf('year')
        .valueOf()
      const endStamp = moment()
        .year(Number(year))
        .tz('America/New_York')
        .endOf('year')
        .valueOf()
      dispatch(
        fetchNews({
          startStamp,
          endStamp,
          type: 'financial',
        }),
      )
      dispatch(
        fetchNews({
          startStamp,
          endStamp,
          type: 'other',
        }),
      )
    }
  }, [year])

  // 绑定数据
  const { RLNews, OTNews, appearYears } = useSelector((state: RootState) => {
    return {
      RLNews: state.news.news,
      OTNews: state.news.otherNews,
      appearYears: state.newsAppearYears.appearYears,
    }
  }, shallowEqual)

  function handleChange(value: string): void {
    setYear(value)
    setActiveKey('1')
  }

  function tabChange(value: string): void {
    setActiveKey(value)
  }

  if (!year && appearYears && appearYears.length > 0) {
    setYear(appearYears[0])
  }

  return (
    <div className={styles.container}>
      <Select
        key={appearYears && appearYears[0] ? appearYears[0] : ''}
        defaultValue={appearYears && appearYears[0] ? appearYears[0] : ''}
        onChange={handleChange}
        loading={!appearYears || !appearYears.length ? true : false}
      >
        {appearYears.map((item) => {
          return (
            <Option value={item} key={item}>
              {item}
            </Option>
          )
        })}
      </Select>
      <div className={styles.content}>
        <Tabs
          className={`${isAndroid ? 'ar-tabs' : null}`}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={tabChange}
        >
          <TabPane tab="Financial Releases" key="1">
            {RLNews.length > 0 ? (
              RLNews.map((item) => {
                return (
                  <RecentNewsItem
                    key={item.shortId}
                    news={item}
                    useShortTime={false}
                  />
                )
              })
            ) : (
              <p className={styles.emptySty}>Not available now</p>
            )}
          </TabPane>
          <TabPane tab="Other Releases" key="2">
            {OTNews.length > 0 ? (
              OTNews.map((item) => {
                return (
                  <RecentNewsItem
                    key={item.shortId}
                    news={item}
                    useShortTime={false}
                  />
                )
              })
            ) : (
              <p className={styles.emptySty}>Not available now</p>
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default YearNews
