import React, { ReactElement } from 'react'
import styles from './ManagementView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'
import pic1 from './pics/zhoufeng.jpg'
import pic2 from './pics/River.jpg'
import pic3 from './pics/jinlei.jpg'
import pic4 from './pics/liurenlei.jpg'
import pic5 from './pics/supeng.jpg'
import pic6 from './pics/Wayne.jpg'

interface ManagementItem {
  name: string
  pic: string
  position: string
  description: { __html: string }
}
function createMarkup(htmlStr: string): { __html: string } {
  return {
    __html: htmlStr,
  }
}
function ManagementView(): ReactElement {
  const managementList: ManagementItem[] = [
    {
      name: 'Feng Zhou',
      pic: pic1,
      position: 'Chief Executive Officer and Director',
      description: createMarkup(
        'Feng Zhou currently serves as Youdao’s Chief Executive Officer and has served as a director since April 2018. Prior to joining Youdao in 2007, Dr. Zhou served as a software engineer at ChinaRen Inc. where he led the development of its internet email system. Dr. Zhou received his bachelor’s degree and master’s degree in computer science from Tsinghua University and received a Ph.D. in computer science from the University of California, Berkeley.',
      ),
    },
    {
      name: 'Lei Jin',
      pic: pic3,
      position: 'President',
      description: createMarkup(
        'Lei Jin currently serves as Youdao’s President. Prior to joining Youdao in 2005, Mr. Jin served as a software engineer at Intel Corporation (Nasdaq: INTC) from 2003 to 2005. Mr. Jin received his bachelor’s degree and master’s degree in computer science from Tsinghua University.',
      ),
    },
    {
      name: 'Yinghui Wu',
      pic: pic2,
      position: 'Vice President',
      description: createMarkup(
        'Yinghui Wu currently serves as Youdao’s Vice President. Prior to joining Youdao in 2005, Mr. Wu served as a technology manager at Sohu.com Limited (Nasdaq: SOHU) from 2004 to 2005. Mr. Wu received his bachelor’s degree and master’s degree in computer science from Tsinghua University.',
      ),
    },
    {
      name: 'Renlei Liu',
      pic: pic4,
      position: 'Vice President',
      description: createMarkup(
        'Renlei Liu currently serves as Youdao’s Vice President. Mr. Liu joined Youdao in 2007 and is currently in charge of the marketing department. Mr. Liu received his master’s degree in business administration from Tsinghua University.',
      ),
    },
    {
      name: 'Peng Su',
      pic: pic5,
      position: 'Vice President',
      description: createMarkup(
        'Peng Su has served as Youdao’s Vice President of Strategies and Capital Markets since March 2019. Prior to joining Youdao, Mr. Su worked at the New York Stock Exchange (China) for over 12 years in various roles, including its Representative and later its Chief Representative. Mr. Su received his master’s degree from North Carolina State University.',
      ),
    },
    {
      name: 'Yongwei Li',
      pic: pic6,
      position: 'Vice President',
      description: createMarkup(
        'Yongwei Li has served as Youdao’s Vice President of Finance since May 2019. Prior to joining Youdao, Mr. Li served as a financial controller at Weibo Corporation (Nasdaq: WB) and Sina Corporation (Nasdaq: SINA) from 2013 to 2019. Mr. Li previously worked at PricewaterhouseCoopers Zhong Tian LLP between 2005 and 2013, with his last role as an audit manager. Mr. Li received his master’s degree in business administration from Jinan University. He is a certified public accountant in the State of New Hampshire and a member of the American Institution of Certified Public Accountants. Mr. Li also qualifies as a member of the Association Chartered Certified Accountant and a member of the Chinese Institute of the Certified Public Accountant.',
      ),
    },
  ]
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.management} />
        <div className={styles.mainContent}>
          {managementList.map((management) => {
            return (
              <div key={management.name} className={styles.managementItem}>
                <div className={styles.title}>
                  <img className={styles.pic} src={management.pic} />
                  <div className={styles.name}>{management.name}</div>
                  <div className={styles.position}>{management.position}</div>
                </div>

                <div className={styles.description}>
                  <article
                    dangerouslySetInnerHTML={management.description}
                  ></article>
                </div>
              </div>
            )
          })}
        </div>
      </span>
    </div>
  )
}

export default ManagementView
