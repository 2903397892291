/**
 *
 * @author fuyg
 * @date  2020-06-29
 */

const root = 'Youdao InvestorRoom'
const joiner = ' - '

function createPageTitle(subTitles?: string[]): string {
  if (!subTitles || !subTitles.length) {
    return root
  }
  const arr = [...subTitles]
  arr.unshift(root)
  return arr.join(joiner)
}

export { createPageTitle }
