/**
 *
 *
 * @author yangxin
 * @date  2020-06-08
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  ReportWithVideo,
  getReportWithVideo,
} from 'src/pages/home/apis/businessIntroVideos'

interface IntroVideosState {
  loading: boolean
  error: string
  reportWithVideos: ReportWithVideo[]
}

const initialState: IntroVideosState = {
  loading: false,
  error: '',
  reportWithVideos: [],
}

const introVideosSlice = createSlice({
  name: 'introVideos',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<ReportWithVideo[]>): void {
      state.loading = false
      const { payload } = action
      if (payload && payload.length >= 0) {
        state.reportWithVideos = action.payload.filter((reportItem) => {
          return reportItem.videoList && reportItem.videoList.length > 0
        })
      }
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = introVideosSlice.actions

export default introVideosSlice.reducer

export const fetchReportWithVideo = (): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getReportWithVideo()
    dispatch(getDataSuccess(data))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
