/**
 *
 * @author fuyg
 * @date  2020-06-04
 */

import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './InvestorEventDetail.module.scss'
import { fetchDetailData } from '../../store/slices/reportDetail'
import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from 'src/pages/home/components/BigTitle'

export interface InvestorEventDetailProps {
  eventId: string
}

function InvestorEventDetail(props: InvestorEventDetailProps): ReactElement {
  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchDetailData({
        id: props.eventId,
      }),
    )
  }, [])

  // 绑定数据
  const { detail } = useSelector((state: RootState) => {
    return {
      detail: state.reportDetail.detail,
    }
  }, shallowEqual)

  function createMarkup(): { __html: string } {
    const html = detail && detail.content ? detail.content : ''
    return {
      __html: html,
    }
  }

  return (
    <div className={styles.container}>
      <BigTitle title={detail && detail.title ? detail.title : ''} />
      <article
        className={styles.article}
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  )
}

export default InvestorEventDetail
