/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { RootState } from 'src/pages/home/store/rootReducer'
import { useParams } from 'react-router-dom'
import styles from './UnsubscribeView.module.scss'
import {
  getSubscribeStatus,
  userUnsubscribe,
} from 'src/pages/home/store/slices/unsubscribe'
import { Button } from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'

function UnsubscribeView(): ReactElement {
  const [showUnsubscribe, setShowUnsubscribe] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const dispatch = useDispatch()
  const { code } = useParams()

  const { subscribed, unsubscribeResponse } = useSelector(
    (state: RootState) => {
      return {
        subscribed: state.unsubscribe.subscribeStatusResponse.data == true,
        unsubscribeResponse: state.unsubscribe.unsubscribeResponse,
      }
    },
    shallowEqual,
  )

  useEffect(() => {
    dispatch(getSubscribeStatus({ code }))
  }, [])

  useEffect(() => {
    setShowUnsubscribe(subscribed)
  }, [subscribed])

  useEffect(() => {
    if (isSubmitted && unsubscribeResponse.code === 0) {
      setShowUnsubscribe(false)
    }
  }, [isSubmitted])

  async function unsubscribe(): Promise<void> {
    setIsSubmitted(false)
    await dispatch(userUnsubscribe({ code }))
    setIsSubmitted(true)
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {showUnsubscribe ? (
          <div>
            <h2>Unsubscribe</h2>
            <p>Are you sure you want to unsubscribe from our emails?</p>
            <Button onClick={unsubscribe}>Unsubscribe</Button>
          </div>
        ) : (
          <div>
            <h2>Unsubscribe</h2>
            <p>
              <span className={styles.icon}>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </span>
              Successfully unsubscribed
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default UnsubscribeView
