/**
 *
 * @author fuyg
 * @date  2020-06-09
 */

import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './NewsDetail.module.scss'
import { fetchNewsDetail } from 'src/pages/home/store/slices/newsDetail'
import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { toNewYorkEst } from 'src/utils/time'

export interface NewsDetailProps {
  newsId: string
}

function NewsDetail(props: NewsDetailProps): ReactElement {
  const { newsId } = props
  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchNewsDetail({
        id: newsId,
      }),
    )
  }, [])

  // 绑定数据
  const { detail } = useSelector((state: RootState) => {
    return {
      detail: state.newsDetail.data,
    }
  }, shallowEqual)

  function createMarkup(): { __html: string } {
    const html = detail && detail.content ? detail.content : ''
    return {
      __html: html,
    }
  }

  return (
    <div className={styles.container}>
      <BigTitle title={detail && detail.title ? detail.title : ''} />
      <p className={styles.time}>
        {detail ? toNewYorkEst(detail.dateStamp) : ''}
      </p>
      <article
        className={styles.article}
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  )
}

export default NewsDetail
