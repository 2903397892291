interface OptionParams {
  value: string | number
  label: string
}

export const PlainOptions: OptionParams[] = [
  { value: 'stock', label: 'Youdao End-of-Day Stock Quote Alert' },
  { value: 'sec', label: 'Youdao SEC Alert' },
  { value: 'news', label: 'Youdao News Alert' },
  { value: 'financial', label: 'Youdao Financial Report Alert' },
]

// 订阅状态
export enum Status {
  START_CONFIRM = 1, // 首次提交订阅
  CONFIRMING, // 确认订阅中
  END_CONFIRM, // 订阅成功
  CANCEL, // 取消订阅
}

export const DescLists: OptionParams[] = [
  {
    value: Status.START_CONFIRM, // 首次提交订阅
    label:
      'Thank you for subscribing to Youdao – IR Site email alerts. A verification email has been sent to your address. ',
  },
  {
    value: Status.CONFIRMING, // 确认订阅
    label: 'Almost there, please click below to confirm your subscription.',
  },
  {
    value: Status.END_CONFIRM, // 添加/取消订阅成功
    label: 'Thank you. Your preferences have been updated.',
  },
  {
    value: Status.CANCEL,
    label: '',
  },
]

// url前缀需要拼接 'mail' 的邮箱列表
export const NeedConcatMailLists: string[] = [
  'rd.netease.com',
  'qq.com',
  '126.com',
  'mf-funds.com',
  'tengyuepartners.com',
  'swsresearch.com',
  'tal.com',
  '17zuoye.com',
  'huaxing.com',
]
