/**
 *
 * @author yangxin
 * @date  2020-06-16
 */

import React, { ReactElement } from 'react'
import styles from './CompanyProfileView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'
import business from 'src/pages/home/views/BusinessOverview/business.png'

function CompanyProfileView(): ReactElement {
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.companyProfile} />
        <div className={styles.mainContent}>
          <p>
            Youdao was founded in 2006 as part of NetEase, a leading internet
            technology company in China, dedicated to providing online services
            centered around content, community, communication and commerce. In
            2007, we launched our flagship Youdao Dictionary, which is China’s
            leading language app. Youdao Dictionary had 48.4 million average
            MAUs in 2021. The early success of Youdao Dictionary has enabled us
            to attract a massive user base, build a strong brand, and expand
            into a broad range of products and services addressing people’s
            lifelong learning needs:
          </p>
          <p className={styles.imgContainer}>
            <img src={business} />
          </p>
          <p>
            <span className={styles.textTitle}>Online Knowledge Tools</span>
            <br />A collection of dictionaries, empowered by leading-edge
            technologies. Our tools are convenient, smart and powerful. We offer
            most of them for free, but monetize their massive user bases mainly
            through advertising. As these tools become ubiquitous in people’s
            lives, they have also helped drive organic user traffic to our
            learning services and other products and services.
          </p>
          <p>
            <span className={styles.textTitle}>Learning Services</span>
            <br />
            Primarily include (1) STEAM courses (operated mainly through Youdao
            Premium Courses), mainly consisting of Youdao Weiqi, Youdao iCode,
            Youdao Chess, Youdao Kada, Youdao Drosophila Science and Youdao Fun
            Reading, (2) adult and vocational courses (operated through NetEase
            Cloud Classroom) including foreign language courses, vocational
            education courses, post-graduate school entrance exam prep courses,
            and digital skills training courses, and (3) other courses such as
            China University MOOC. We use our extensive experience and know-how
            from the current course offerings to train instructors and teaching
            assistants and inform our course development, which help us attract
            and retain students. We integrate our core technologies into the
            learning and teaching process to create an engaging, interactive and
            customized learning experience across our learning services.
          </p>
          <p>
            <span className={styles.textTitle}>Smart Devices</span>
            <br />
            Primarily include Youdao Dictionary Pen, Youdao Listening Pod,
            Youdao Smart Light, Youdao Pocket Translator and Youdao Super
            Dictionary. Our approach to such devices is a seamless integration
            of AI algorithms and data processing into hardware devices that
            supplement our online knowledge tools and learning services. Our
            smart devices allow us to further enhance users’ learning experience
            and efficiency.
          </p>
          <p>
            <span className={styles.textTitle}>
              Education Digitalization Solutions
            </span>
            <br />
            Include technologies and solutions licensed to schools or enterprise
            customers, such as Youdao Smart Learning Terminal, and Youdao Smart
            Cloud.
          </p>
          <p>
            Our products and services are built upon a common set of core
            technologies, which allows us to use data insights gained from an
            individual product or service to help optimize our entire product
            and service portfolio. Our business has evolved significantly since
            inception and we are continuously reimagining and innovating our
            products and services. We are doing this not only to cater to, but
            influence, the learning habits and lifestyles of our users, to
            fulfill their goals and enrich their lives.
          </p>
        </div>
      </span>
    </div>
  )
}

export default CompanyProfileView
