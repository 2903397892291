/**
 *
 * @author wangjm
 * @date  2020-06-09
 */

import React, { ReactElement /*useEffect, useState */ } from 'react'
// import { /*useSelector,*/ useDispatch /*shallowEqual*/ } from 'react-redux'
import styles from './BoardCommittees.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
// import { Form, Input, Button } from 'antd'
import { BigTitle } from 'src/pages/home/components/BigTitle'

import chair from './icon_chair.svg'
import member from './icon_member.svg'
import { navText } from 'src/utils/navText'

function BoardCommittees(): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(fetchData())
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  return (
    <div className={styles.container}>
      <BigTitle title={navText.boardCommittees} />
      <p>
        Below is a summary of our committee structure and membership
        information.
      </p>
      <div className={styles.content}>
        <table>
          <tbody>
            <tr>
              <td></td>
              <td>Audit Committee</td>
              <td>Compensation Committee</td>
              <td>
                Nominating and Corporate
                <br />
                Governance Committee
              </td>
            </tr>
            <tr>
              <td align={'left'}>Wiliam Lei Ding</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td align={'left'}>Feng Zhou</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td align={'left'}>Harry Heung Yeung Shum</td>
              <td>
                <img src={member} />
              </td>
              <td>
                <img src={chair} />
              </td>
              <td>
                <img src={chair} />
              </td>
            </tr>
            <tr>
              <td align={'left'}>Jimmy Lai</td>
              <td>
                <img src={chair} />
              </td>
              <td>
                <img src={member} />
              </td>
              <td>
                <img src={member} />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.note}>
          <span>
            <img src={chair} />
            Chair
          </span>
          <span>
            <img src={member} />
            Member
          </span>
        </div>
      </div>
    </div>
  )
}

export default BoardCommittees
