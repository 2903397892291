/**
 *
 * @author yangxin
 * @date  2020-06-10
 */
import React, { ReactElement } from 'react'
import styles from './SECFilingView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { FilingsDisplay } from 'src/pages/home/components/FilingsDisplay'
import { navText } from 'src/utils/navText'

function SECFilingView(): ReactElement {
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.secFilings} />
        <div className={styles.description}>
          Select the filing type and date range you wish to view from the
          drop-down menus below.
        </div>
        <FilingsDisplay />
      </span>
    </div>
  )
}

export default SECFilingView
