/**
 *
 * @author bjhuangdy
 * @date  2022-10-11
 */

import React, { ReactElement, useState, useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import styles from './StockInfo.module.scss'
import { StockItemData } from 'src/pages/home/apis/stock'

interface StockChartProps {
  list: StockItemData[]
}

function StockChart({ list }: StockChartProps): ReactElement {
  const isMobile = (): boolean => {
    const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
    return flag
  }

  const stockOptions = {
    rangeSelector: {
      // 时间范围按钮数组
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
      selected: 2, // 默认选中的范围，值为上面 buttons 数组的下标（从 0 开始）
      inputEnabled: !isMobile(), // 不显示日期输入框，移动端不展示
    },
    chart: {
      // 决定用户可以通过拖动鼠标缩放的尺寸。可以是x,y 或xy中的一个
      zoomType: 'x',
    },
    tooltip: {
      // 保留多少位小数
      valueDecimals: 2,
      // 日期和内容是否分割显示，默认是true, 日期会显示在X轴附近；值为false时日期和内容都一起显示在tooltip上
      split: false,
    },
    credits: {
      enabled: false, // 隐藏右下角官网链接
    },
    legend: {
      enabled: false,
    },
    scrollbar: {
      liveRedraw: false,
    },
    yAxis: [
      {
        height: '100%',
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: '',
        },
      },
    ],
    series: [
      {
        data: [],
        name: 'NYSE:DAO',
        lineWidth: 2,
        boostThreshold: 1,
        turboThreshold: 1,
        showInNavigator: true,
        dataGrouping: {
          enabled: false,
        },
      },
    ],
  }
  const [options, setOptions] = useState()

  // 转化时间戳
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const conversionTimestamp = (arr: StockItemData[] = []): any => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newArr: any = []
    arr.forEach((item: StockItemData) => {
      const { year, month, day } = item.tradeDate
      // 月份和日期不满两位补 0， 避免移动端转化为时间戳时转化失败的问题
      const m = month >= 10 ? month : '0' + month
      const d = day >= 10 ? day : '0' + day
      const date = `${year}-${m}-${d}`
      // 转时间戳，`moment(date).valueOf()`会从零点开始,highcharts会把这个时间算到前一天，因此换成 `Date.parse`方式（从早上8点开始）
      const timestamp = Date.parse(date)
      const itemData = [timestamp, Number(item.closePrice)]
      newArr.push(itemData)
    })
    return newArr
  }

  useEffect(() => {
    if (!list.length) return
    const result = conversionTimestamp(list)
    // 拿到数据后再初始化股价图配置和数据，不然时间范围的all属性会不起作用
    const stockOp = JSON.parse(JSON.stringify({ ...stockOptions }))
    stockOp.series[0].data = result
    // 更新股价图数据
    setOptions(stockOp)
  }, [list])

  return (
    <div className={styles['stock-info-wrap']}>
      <h2>Stock chart</h2>
      <HighchartsReact
        constructorType={'stockChart'}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

export default StockChart
