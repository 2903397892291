/**
 *
 *
 * @author fuyg
 * @date  2020-06-10
 */
import { axios, API_PREFIX } from 'src/http'
import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/operatingData`

interface RawOperatingDataItem {
  adults: string
  id: number
  k12: string
  name: string
  onlineCourses: string
  premiumCourses: string
}

export interface OperatingDataItem extends RawOperatingDataItem {
  shortId: string
}

function parseResponseData(data: RawOperatingDataItem[]): OperatingDataItem[] {
  if (!data || !data.length) {
    return []
  }

  return data.map(
    (item: RawOperatingDataItem): OperatingDataItem => {
      return {
        ...item,
        shortId: shortid.generate(),
      }
    },
  )
}

async function getOperatingData(): Promise<OperatingDataItem[]> {
  const url = theApi
  const response = await axios.get(url)
  const parsed = parseResponseData(response.data.data as RawOperatingDataItem[])
  return parsed
}

export { getOperatingData }
