/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement } from 'react'
import styles from './InvestorEventItem.module.scss'
import { WebcastButton } from 'src/pages/home/components/WebcastButton'
import { DownloadButton } from 'src/pages/home/components/DownloadButton'
import { SupplementalButton } from 'src/pages/home/components/SupplementalButton'
import { InvestorEvent } from 'src/pages/home/apis/recentInvestorEvents'
import { toNewYorkEst } from 'src/utils/time'
import { shouldOpenNewPage } from 'src/utils/docType'
// import { Link } from 'react-router-dom'
// import { buildRoutePath } from 'src/utils/buildRoutePath'

interface InvestorEventItemProps {
  event: InvestorEvent
  showVideo?: boolean
  shortTitle?: boolean
}

interface InvestorEventItemState {
  stateName: string
}

function InvestorEventItem(props: InvestorEventItemProps): ReactElement {
  const { event, showVideo } = props
  // const linkUrl = buildRoutePath('EVENT_INVESTOR_DETAIL', {
  //   eventId: event.id,
  // })
  const title =
    props.shortTitle && event.title.length > 80
      ? event.title.substring(0, 80) + '...'
      : event.title
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.time}>{toNewYorkEst(event.time)}</p>
      <div className={styles.buttons}>
        <WebcastButton url={event.webCast} text={event.webCastName} />
        {event.annexList.map((annex) => {
          return (
            <DownloadButton
              key={annex.shortId}
              url={annex.url}
              downloadName={annex.url}
              text={annex.name}
              openInNewPage={shouldOpenNewPage(annex.url)}
            />
          )
        })}
        {showVideo && event.videoList.length ? <SupplementalButton /> : ''}
      </div>
    </div>
  )
}
InvestorEventItem.defaultProps = {
  showVideo: true,
  shortTitle: false,
}
export default InvestorEventItem
