/**
 *
 * @author yangxin
 * @date  2020-07-09
 */
import moment from 'moment'

function getYearsFromStamp(stampList: number[]): string[] {
  const dateList = stampList.map((stamp) => {
    return moment(stamp).tz('America/New_York').format('YYYY')
  })
  return Array.from(new Set(dateList))
}

export { getYearsFromStamp }
