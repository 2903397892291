/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ReadMoreButton } from 'src/pages/home/components/ReadMoreButton'
import styles from './RecentNews.module.scss'
import { fetchRecentNews } from 'src/pages/home/store/slices/recentNews'
import { RootState } from 'src/pages/home/store/rootReducer'
import { FinancialReleases } from '../FinancialReleases'
import { OtherReleases } from '../OtherReleases'

function RecentNews(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchRecentNews({
        count: 3,
        type: 'financial',
      }),
    )
    dispatch(
      fetchRecentNews({
        count: 3,
        type: 'other',
      }),
    )
  }, [])

  const { recentNews, otherRecentNews } = useSelector((state: RootState) => {
    return {
      recentNews: state.recentNews.recentNews,
      otherRecentNews: state.recentNews.otherRecentNews,
    }
  }, shallowEqual)

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Recent News</h3>
      <div className={styles.content}>
        <FinancialReleases RLnews={recentNews} />
        <OtherReleases OTnews={otherRecentNews} />
      </div>
      <p>
        <ReadMoreButton routeName="EVENT_NEWS" />
      </p>
    </div>
  )
}

export default RecentNews
