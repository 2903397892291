/**
 *
 *
 * @author fuyg
 * @date  2020-06-22
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  FinancialConfig,
  getFinancialConfig,
} from 'src/pages/home/apis/financialConfig'

interface FinancialConfigState {
  loading: boolean
  error: string
  data: FinancialConfig | null
}

const initialState: FinancialConfigState = {
  loading: false,
  error: '',
  data: null,
}

const financialConfigSlice = createSlice({
  name: 'financialConfigSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<FinancialConfig>): void {
      state.loading = false
      state.data = action.payload
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = financialConfigSlice.actions

export default financialConfigSlice.reducer

export const fetchFinancialConfig = (): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getFinancialConfig()
    dispatch(getDataSuccess(data))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
