/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { InvestorEventItem } from 'src/pages/home/components/InvestorEventItem'
import styles from './InvestorEvents.module.scss'
import { ReadMoreButton } from 'src/pages/home/components/ReadMoreButton'
import { RootState } from 'src/pages/home/store/rootReducer'
import { fetchRecentInvestorEvents } from 'src/pages/home/store/slices/recentInvestorEvents'

interface InvestorEventsProps {
  propName: string
}

interface InvestorEventsState {
  stateName: string
}

function InvestorEvents(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchRecentInvestorEvents({
        count: 2,
      }),
    )
  }, [])
  const { events } = useSelector((state: RootState) => {
    return {
      events: state.recentInvestorEvents.events,
      loading: state.recentInvestorEvents.loading,
    }
  }, shallowEqual)

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Investor Events</h3>
      <div className={styles.content}>
        {events.map((item) => {
          return (
            <InvestorEventItem
              key={item.shortId}
              event={item}
              shortTitle={true}
            />
          )
        })}
      </div>
      <p>
        <ReadMoreButton routeName="EVENT_INVESTOR" />
      </p>
    </div>
  )
}

export default InvestorEvents
