/**
 *
 *
 * @author fuyg
 * @date  2020-06-04
 */

import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'
import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/news`

export interface NewsRequestParams {
  startStamp: number
  endStamp: number
  type: string
}

export interface RawNewsSummary {
  shortId: string | number | undefined
  id: string
  dateStamp: number
  title: string
}

export interface NewsSummary extends RawNewsSummary {
  shortId: string
}

export interface NewsActionParams {
  type: string
  data: NewsSummary[]
}

function parseNewsSummary(news: RawNewsSummary[]): NewsSummary[] {
  if (!news || !news.length) {
    return []
  }
  return news.map((item) => {
    return {
      ...item,
      shortId: shortid.generate(),
    }
  })
}

async function getNews(params: NewsRequestParams): Promise<NewsSummary[]> {
  const search = {
    startStamp: params.startStamp.toString(),
    endStamp: params.endStamp.toString(),
    type: params.type.toString(),
  }
  const url = buildUrl(theApi, search)
  const response = await axios.get(url)
  const parsed = parseNewsSummary(response.data.data as RawNewsSummary[])
  return parsed
}

export { getNews, parseNewsSummary }
