/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement } from 'react'
import styles from './EventsAndNews.module.scss'
import { InvestorEvents } from 'src/pages/home/components/InvestorEvents'
import { RecentNews } from 'src/pages/home/components/RecentNews'

interface EventsAndNewsProps {
  propName: string
}

interface EventsAndNewsState {
  stateName: string
}

class EventsAndNews extends React.Component<
  EventsAndNewsProps,
  EventsAndNewsState
> {
  /***************************************************************************
   *  static properties and methods
   **************************************************************************/

  static defaultProps = {
    // TODO
  }

  constructor(props: EventsAndNewsProps) {
    super(props)

    this.state = {
      stateName: 'TODO',
    }

    // bind this
    this.nameMethod = this.nameMethod.bind(this)
  }

  render(): ReactElement {
    return (
      <div className={styles.container}>
        <InvestorEvents />
        <RecentNews />
      </div>
    )
  }

  /***************************************************************************
   *  custom methods
   **************************************************************************/

  nameMethod(): void {
    // TODO
  }

  /***************************************************************************
   * lifecycle methods
   **************************************************************************/

  // componentDidMount() {}

  // componentDidUpdate(prevProps, prevState, snapshot) {}

  // componentWillUnmount() {}

  /* rarely used lifecycle methods */

  // static getDerivedStateFromProps(props, state) {}

  // shouldComponentUpdate(nextProps, nextState) {}

  // getSnapshotBeforeUpdate(prevProps, prevState) {}

  /* error boundaries */

  // static getDerivedStateFromError(error) {}

  // componentDidCatch(error, info) {}

  /* legacy lifecycle methods */

  // UNSAFE_componentWillMount() {}

  // UNSAFE_componentWillReceiveProps(nextProps) {}

  // UNSAFE_componentWillUpdate(nextProps, nextState) {}
}

export default EventsAndNews
