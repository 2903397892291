/**
 *
 * @author fuyg
 * @date  2020-06-09
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './EventCalendarArticle.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { EventCalendar } from 'src/pages/home/components/EventCalendar'
import { navText } from 'src/utils/navText'

function EventCalendarArticle(): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  // dispatch(fetchData({}))
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  return (
    <div className={styles.container}>
      <BigTitle title={navText.eventCalendar} />
      <p className={styles.description}>
        Browse the calendar to view Investor related events and announcements.
        Webcasts and presentations that have been archived are available by
        viewing historical event details.
      </p>
      <EventCalendar />
    </div>
  )
}

export default EventCalendarArticle
