/**
 *
 * @author wangjm
 * @date  2020-06-17
 */

import React, { ReactElement /*useEffect, useState*/ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './QuanterlyEarnings.module.scss'
import { navText } from 'src/utils/navText'
import { YearInvestorEvents } from '../../components/YearInvestorEvents'
import { BigTitle } from '../../components/BigTitle'

function QuanterlyEarnings(): ReactElement {
  return (
    <div className={styles.container}>
      <BigTitle title={navText.quanterlyEarnings} />
      <div className={styles.content}>
        <YearInvestorEvents showVideo={false} />
      </div>
    </div>
  )
}

export default QuanterlyEarnings
