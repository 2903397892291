/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement } from 'react'
import styles from './BigTitle.module.scss'
// import classnames from 'classnames'

export interface BigTitleProps {
  title: string
  level?: 1 | 2 | 3
  subTitle?: string
}

function BigTitle(props: BigTitleProps): ReactElement {
  return (
    <div className={styles.container}>
      <div className={`level-${props.level} ${styles.title}`}>
        {props.title} {props.subTitle ? `- ${props.subTitle}` : ''}
      </div>
    </div>
  )
}

BigTitle.defaultProps = {
  level: 1,
}

export default BigTitle
