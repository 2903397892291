/**
 *
 * @author fuyg
 * @date  2020-06-09
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './NewsDetailView.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { RouteConfigComponentProps } from 'react-router-config'
import { NewsDetail } from 'src/pages/home/components/NewsDetail'

interface RouteParams {
  newsId: string
}

function NewsDetailView(
  props: RouteConfigComponentProps<RouteParams>,
): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  // dispatch(fetchData({}))
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  const { match } = props
  const { params } = match
  const { newsId } = params
  // console.log(match)

  return (
    <div className={styles.container}>
      <NewsDetail newsId={newsId} />
    </div>
  )
}

export default NewsDetailView
