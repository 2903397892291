import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  getStatus,
  unsubscribe,
  SubscribeStatusParams,
  SubscribeStatusResponse,
  UnsubscribeParams,
  UnsubscribeResponse,
} from 'src/pages/home/apis/unsubscribe'

interface UnsubscribeState {
  subscribeStatusLoading: boolean
  subscribeStatusError: string
  unsubscribeLoading: boolean
  unsubscribeError: string
  subscribeStatusResponse: SubscribeStatusResponse
  unsubscribeResponse: UnsubscribeResponse
}
const initialState: UnsubscribeState = {
  subscribeStatusLoading: false,
  subscribeStatusError: '',
  unsubscribeLoading: false,
  unsubscribeError: '',
  subscribeStatusResponse: { data: false },
  unsubscribeResponse: { code: -1, message: '' },
}

const subscribeSlice = createSlice({
  name: 'unsubscribeSlice',
  initialState,
  reducers: {
    getStatusStart(state): void {
      state.subscribeStatusLoading = true
    },
    getStatusSuccess(
      state,
      action: PayloadAction<SubscribeStatusResponse>,
    ): void {
      state.subscribeStatusLoading = false
      state.subscribeStatusResponse = action.payload
    },
    getStatusError(state, action: PayloadAction<string>): void {
      state.subscribeStatusLoading = false
      state.subscribeStatusError = action.payload
    },
    getUnsubscribeStart(state): void {
      state.unsubscribeLoading = true
    },
    getUnsubscribeSuccess(
      state,
      action: PayloadAction<UnsubscribeResponse>,
    ): void {
      state.unsubscribeLoading = false
      state.unsubscribeResponse = action.payload
    },
    getUnsubscribeError(state, action: PayloadAction<string>): void {
      state.unsubscribeLoading = false
      state.unsubscribeError = action.payload
    },
  },
})

export const {
  getStatusStart,
  getStatusSuccess,
  getStatusError,
  getUnsubscribeStart,
  getUnsubscribeSuccess,
  getUnsubscribeError,
} = subscribeSlice.actions

export default subscribeSlice.reducer

export const getSubscribeStatus = (
  params: SubscribeStatusParams,
): AppThunk => async (dispatch): Promise<SubscribeStatusResponse | void> => {
  try {
    dispatch(getStatusStart())
    const data = await getStatus(params)
    dispatch(getStatusSuccess(data))
    return data
  } catch (err) {
    dispatch(getStatusError(err as string))
  }
}

export const userUnsubscribe = (params: UnsubscribeParams): AppThunk => async (
  dispatch,
): Promise<UnsubscribeResponse | void> => {
  try {
    dispatch(getUnsubscribeStart)
    const data = await unsubscribe(params)
    dispatch(getUnsubscribeSuccess(data))
    return data
  } catch (err) {
    dispatch(getUnsubscribeError(err as string))
  }
}
