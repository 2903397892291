/**
 *
 * @author yangxin
 * @date  2020-06-12
 */

import React, { ReactElement, useEffect, useState } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './FilingsTable.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'

import { FilingItem } from 'src/pages/home/apis/SECFilings'
import { Table } from 'antd'
import { FilingButton } from 'src/pages/home/components/FilingButton'
import { docType } from 'src/utils/docType'
import { scrollToTop } from 'src/utils/scroll'
import shortid from 'shortid'
import moment from 'moment'
import { TablePaginationConfig } from 'antd/lib/table'
import { ReactComponent as PrePageIcon } from './prePage.svg'
import { ReactComponent as NextPageIcon } from './nextPage.svg'

export interface FilingsTableProps {
  filingsData: FilingItem[]
  totalCount: number
  pageIndex: number
  pageIndexChange: Function
}

interface TableDataItem {
  key: string
  form: string
  description: string
  date: string
  format: LinkItem[]
}
interface LinkItem {
  type: string
  url: string
  shortId: string
}

const PAGE_COUNT = 20
const PAGINATION_POSITION = 'bottomLeft'

function itemRender(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  current: any,
  type: string,
  originalElement: ReactElement,
): ReactElement {
  if (type === 'prev') {
    return <PrePageIcon />
  }
  if (type === 'next') {
    return <NextPageIcon />
  }
  return originalElement
}

function FilingsTable(props: FilingsTableProps): ReactElement {
  const [paginationOptions, setPaginationOptions] = useState<
    TablePaginationConfig
  >({
    current: props.pageIndex,
    position: [PAGINATION_POSITION],
    pageSize: PAGE_COUNT,
    total: props.totalCount,
    itemRender,
  })
  // 获取数据
  // const dispatch = useDispatch()

  useEffect(() => {
    setPaginationOptions({
      current: props.pageIndex,
      position: [PAGINATION_POSITION],
      pageSize: PAGE_COUNT,
      total: props.totalCount,
      itemRender,
    })
  }, [props.filingsData])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  //   return {
  //     data: state.sliceName.data,
  //   }
  // }, shallowEqual)

  const columns = [
    {
      title: 'Form',
      dataIndex: 'form',
      key: 'form',
      width: '10%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (text: any, record: TableDataItem): any =>
        record.format.map((linkItem) => {
          return linkItem.url ? (
            <FilingButton
              key={linkItem.shortId}
              type={linkItem.type}
              url={linkItem.url}
              downloadName="youdao"
            />
          ) : null
        }),
    },
  ]

  const data = props.filingsData.map((filingItem /*, index*/) => {
    return {
      key: filingItem.shortId,
      form: filingItem.formType,
      description: filingItem.desc,
      date: moment(filingItem.dateStamp).format('MMM D, YYYY'),
      format: [
        {
          type: docType.HTML,
          url: filingItem.htmlUrl,
          shortId: shortid.generate(),
        },
        {
          type: docType.WORD,
          url: filingItem.wordUrl,
          shortId: shortid.generate(),
        },
        {
          type: docType.PDF,
          url: filingItem.pdfUrl,
          shortId: shortid.generate(),
        },
        {
          type: docType.EXCEL,
          url: filingItem.excelUrl,
          shortId: shortid.generate(),
        },
        // {
        //   type: docType.XBRL,
        //   url: filingItem.xbrlDocUrl,
        //   shortId: shortid.generate(),
        // },
      ],
    }
  })

  function handleTableChange(pagination: TablePaginationConfig): void {
    scrollToTop()
    props.pageIndexChange(pagination.current)
    setPaginationOptions({
      current: pagination.current,
      position: [PAGINATION_POSITION],
      pageSize: PAGE_COUNT,
      total: props.totalCount,
      itemRender,
    })
  }

  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        pagination={paginationOptions}
        dataSource={data}
        onChange={handleTableChange}
      />
    </div>
  )
}

export default FilingsTable
