/**
 *
 * @author yangxin
 * @date  2020-06-08
 */

import React, { ReactElement, useEffect, useState } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './VideoPlayer.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'

import videojs, { VideoJsPlayerOptions, VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'

export interface VideoPlayerProps {
  playerOptions: VideoJsPlayerOptions
  videoPlayerId: string
  changeActivePlayer?: Function
  activePlayerId?: string
}

function VideoPlayer(props: VideoPlayerProps): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null)
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (videoNode) {
      const player = videojs(videoNode, props.playerOptions)
      setPlayer(player)
      player.ready(() => {
        player.on('play', () => {
          if (props.changeActivePlayer) {
            props.changeActivePlayer(props.videoPlayerId)
          }
        })
      })
    }
  }, [videoNode])

  useEffect(() => {
    if (!player) {
      return
    }

    if (props.activePlayerId !== props.videoPlayerId && player.pause) {
      setTimeout(() => {
        player.pause()
      }, 500)
    }
  }, [props.activePlayerId])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  //   return {
  //     data: state.sliceName.data,
  //   }
  // }, shallowEqual)

  return (
    <div className={styles.container}>
      <div data-vjs-player>
        <video
          webkit-playsinline="true"
          playsInline={true}
          preload="auto"
          ref={(node): void => {
            setVideoNode(node)
          }}
          className="video-js vjs-fluid"
        ></video>
      </div>
    </div>
  )
}

export default VideoPlayer
