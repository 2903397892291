/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './NewsEvents.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'
import { SideNavigation, NavItem } from 'src/components/SideNavigation'
import shortid from 'shortid'
import routePath from 'src/pages/home/router/routePath'
import { navText } from 'src/utils/navText'

function NewsEvents(props: RouteConfigComponentProps): ReactElement {
  const navList: NavItem[] = [
    {
      id: shortid.generate(),
      name: navText.investorEvents,
      routeLink: routePath.EVENT_INVESTOR,
    },
    // {
    //   id: shortid.generate(),
    //   name: navText.eventCalendar,
    //   routeLink: routePath.EVENT_CALENDAR,
    // },
    {
      id: shortid.generate(),
      name: navText.recentNews,
      routeLink: routePath.EVENT_NEWS,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <SideNavigation navList={navList} />
      </div>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default NewsEvents
