/**
 *
 * @author wangjm
 * @date  2020-06-09
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './EmailAlert.module.scss'
import { RootState } from 'src/pages/home/store/rootReducer'
import { Form, Input, Button, message, Checkbox } from 'antd'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { subscribeEmail } from 'src/pages/home/store/slices/subscribe'
import {
  SubscribeParams,
  CheckBoxParams,
  confirm,
  ConfirmParams,
  getSubscribedTypes,
} from '../../apis/subscribe'
import { navText } from 'src/utils/navText'
import { scrollToTop } from 'src/utils/scroll'
import { find } from 'lodash'
import {
  PlainOptions,
  Status,
  DescLists,
  NeedConcatMailLists,
} from './contants'

const CheckboxGroup = Checkbox.Group

function EmailAlert(): ReactElement {
  // 获取数据
  const dispatch = useDispatch()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [checkedList, setCheckedList] = React.useState<string[]>([])
  const [email, setEmail] = React.useState<string>('')
  const [subscribeStatus, setSubscribeStatus] = React.useState<number>(0)
  const [desc, setDesc] = React.useState<string>('')
  const [searchParams, setSearchParams] = React.useState({})
  const [showDesc1, setShowDesc1] = React.useState(false)
  const [subTtitle, setSubTtitle] = React.useState<string>('')

  // 获取当前邮箱订阅类型
  const getSubscribed = async (cid: string): Promise<void> => {
    const res = await getSubscribedTypes({ cid })
    if (res.code === 0) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = res.data as any
      const checked = Object.keys(data).filter(
        (key: string): boolean => data[key].toString() === 'true',
      )
      setCheckedList(checked)
    } else {
      message.error(res?.message || 'Get Subscribe Failed!')
    }
  }

  useEffect(() => {
    const value = window.location.hash
    if (value.includes('?')) {
      const urlSearch = value.split('?')[1]
      const objParams = Object.fromEntries(new URLSearchParams(urlSearch))
      setSearchParams(objParams)
      if (objParams.subscription === 'true') {
        // 添加订阅
        setSubTtitle('Confirm Subscription')
        setSubscribeStatus(Status.CONFIRMING)
      } else {
        // 取消订阅
        getSubscribed(objParams.cid)
        setEmail(objParams.email)
        setSubTtitle('Cancel Subscription')
        setSubscribeStatus(Status.CANCEL)
      }
      setIsSuccess(true)
    }
  }, [])

  useEffect(() => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const desc: any = find(DescLists, { value: subscribeStatus }) ?? {}
    setDesc(desc.label || '')
    const showDesc1 =
      !subscribeStatus || subscribeStatus === Status.START_CONFIRM
    setShowDesc1(showDesc1)
  }, [subscribeStatus])

  // TODO
  const validateMessages = {
    required: '${label} is required',
    types: {
      email: '${label} is not validate email',
    },
  }

  const { data } = useSelector((state: RootState) => {
    return {
      data: state.subscribe.data,
    }
  }, shallowEqual)

  useEffect(() => {
    if (isSubmitted) {
      if (data?.code === 0) {
        // 订阅成功
        setIsSuccess(true)
        setSubscribeStatus(Status.START_CONFIRM)
        scrollToTop()
      } else if (data?.code === 1002) {
        message.warning(
          'This Email address is already subscribed to our newsletter',
        )
      } else {
        message.error(data?.message || 'Subscribe Failed!')
      }
    }
  }, [isSubmitted])

  // 筛选订阅类型
  const filterCheckedType = (
    checkLists: string[],
    status: boolean,
  ): CheckBoxParams => {
    return checkLists.reduce((prev: CheckBoxParams, next: string) => {
      return { ...prev, [next]: status }
    }, {})
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function onFinish(values: any): Promise<void> {
    const { name, email, company, comment, checkLists = [] } = values.sendEmail
    if (name.trim().length === 0) {
      message.warning('Name is required')
      return
    }
    setEmail(email)

    const params: SubscribeParams = Object.assign(
      {
        name,
        email,
      },
      company ? { company } : {},
      comment ? { comment } : {},
      filterCheckedType(checkLists, true),
    )
    setIsSubmitted(false)
    await dispatch(subscribeEmail(params))
    setIsSubmitted(true)
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (checkedValue: any[]): void => {
    setCheckedList(checkedValue)
  }

  /* 邮箱跳转规则：
   * 1. url前缀需要拼接 'mail'
   * 2. 按邮箱@后缀跳转网站，比如：xxx@yahoo.co.uk -->  直接跳转 http://yahoo.co.uk
   * 3. 非上面的两种情况且邮箱地址存在，比如：foxmail.com --> mail.qq.com;   corp --> corp.netease.com/coremail
   */
  const handleLoginEmail = (): void => {
    const emailSuffix = email.split('@')[1]
    let url = ''
    if (NeedConcatMailLists.includes(emailSuffix)) {
      url = `http://mail.${emailSuffix}`
    } else if (emailSuffix === 'foxmail.com') {
      url = 'https://mail.qq.com'
    } else if (emailSuffix === 'corp.netease.com') {
      url = 'https://corp.netease.com/coremail/'
    } else {
      url = `http://${emailSuffix}`
    }
    location.href = url
  }

  // 更新确认和取消订阅成功后的页面交互
  const updateInteraction = (): void => {
    setIsSuccess(true)
    setSubscribeStatus(Status.END_CONFIRM)
    scrollToTop()
  }

  const updateReq = async (params: ConfirmParams): Promise<void> => {
    const res = await confirm(params)
    if (res.code === 0) {
      updateInteraction()
    } else {
      message.error(res.message || 'Update Subscribe Failed!')
    }
  }

  // 确认订阅
  const onConfirmSubscibe = (): void => {
    const _searchParams = Object.assign({}, searchParams) as ConfirmParams
    delete _searchParams['subscription']
    updateReq(_searchParams)
  }

  // 取消订阅
  const onCancelSubcribe = (): void => {
    if (checkedList.length === 0) {
      message.warning('Please select the cancel option first')
      return
    }

    const params = {
      ...filterCheckedType(checkedList, false),
      cid: (searchParams as ConfirmParams).cid,
    }
    updateReq(params)
  }

  return (
    <div className={styles.container}>
      <BigTitle
        title={navText.emailAlerts}
        subTitle={subscribeStatus ? subTtitle : ''}
      />
      {showDesc1 && (
        <p className={styles.desc1}>Get Updates Protips Every Newsletter</p>
      )}
      {isSuccess ? (
        <div>
          <p className={styles['confirm-desc']}>{desc}</p>
          {subscribeStatus === Status.START_CONFIRM && (
            <p className={styles['email-link']} onClick={handleLoginEmail}>
              Please click on the link provided in the email message to verify
              your information.
            </p>
          )}
          {subscribeStatus === Status.CONFIRMING && (
            <div className={styles['confirm-btn']} onClick={onConfirmSubscibe}>
              Confirm your subscription
            </div>
          )}
          {subscribeStatus === Status.CANCEL && (
            <div>
              <div className={styles['email-info']}>
                <span className={styles['text']}>Email:</span>
                <span>{email}</span>
              </div>
              <CheckboxGroup
                options={PlainOptions}
                value={checkedList}
                onChange={onChange}
              />
              <div
                className={styles['unsubscribe-btn']}
                onClick={onCancelSubcribe}
              >
                Unsubscribe from selected
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.content}>
          <p className={styles.desc2}>
            Required Fields <span className={styles.star}>*</span>
          </p>
          <div className={styles.bordered}></div>
          <Form
            name="email-form"
            onFinish={onFinish}
            validateMessages={validateMessages}
            colon={false}
            labelAlign="left"
            layout="vertical"
          >
            <Form.Item
              name={['sendEmail', 'checkLists']}
              label="Alert type"
              rules={[{ required: true }]}
            >
              <CheckboxGroup
                options={PlainOptions}
                value={checkedList}
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item
              name={['sendEmail', 'name']}
              label="Name"
              rules={[{ required: true }]}
            >
              <Input
                spellCheck={false}
                maxLength={100}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name={['sendEmail', 'email']}
              label="Email Address"
              rules={[{ required: true, type: 'email', max: 100 }]}
            >
              <Input
                spellCheck={false}
                maxLength={100}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item name={['sendEmail', 'company']} label="Company">
              <Input
                spellCheck={false}
                maxLength={100}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item name={['sendEmail', 'comment']} label="Comments">
              <Input.TextArea spellCheck={false} maxLength={500} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  )
}

export default EmailAlert
