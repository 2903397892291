/**
 *
 * @author yangxin
 * @date  2020-06-17
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, /*useDispatch,*/ shallowEqual } from 'react-redux'
import styles from './VideosFlowView.module.scss'
// import { fetchReportWithVideo } from 'src/pages/home/store/slices/businessIntroVideos'
import { RootState } from 'src/pages/home/store/rootReducer'
import { VideoItem } from 'src/pages/home/apis/recentInvestorEvents'

import { isMobile } from 'src/utils/isMobile'
import classnames from 'classnames'

import { VideoPlayer } from 'src/components/VideoPlayer'
import { toNewYorkEst } from 'src/utils/time'
// import routePath from 'src/pages/home/router/routePath'

function VideosFlowView(): ReactElement {
  const [activePlayerId, setActivePlayerId] = useState('')
  const [isCurrentMobile, setIsCurrentMobile] = useState(false)

  useEffect(() => {
    const isMobileTag = isMobile()
    setIsCurrentMobile(isMobileTag)
  }, [])

  // useEffect(() => {
  //   dispatch(fetchReportWithVideo())
  // }, [])

  // 绑定数据
  const { reportWithVideos } = useSelector((state: RootState) => {
    return {
      reportWithVideos: state.businessIntroVideos.reportWithVideos,
    }
  }, shallowEqual)

  function handleActivePlayerChange(activePlayerId: string): void {
    setActivePlayerId(activePlayerId)
  }
  return (
    <div
      className={classnames(
        styles.container,
        isCurrentMobile ? '' : styles.pcCollapse,
      )}
    >
      <div id="locationPoint" className={styles.content}>
        <div className={styles.mainContent} id="collapse">
          {reportWithVideos.map((reportItem) => {
            const videoList = reportItem.videoList.map(
              (videoItem: VideoItem) => {
                const videoPlayerOption = {
                  autoplay: false,
                  controls: true,
                  sources: [
                    {
                      src: videoItem.url,
                    },
                  ],
                  aspectRatio: '16:9',
                }
                return (
                  <div key={videoItem.shortId}>
                    <div className={styles.videoName}>{videoItem.name}</div>
                    <div className={styles.time}>
                      {toNewYorkEst(videoItem.time)}
                    </div>
                    <div className={styles.videoContainer}>
                      <VideoPlayer
                        playerOptions={videoPlayerOption}
                        videoPlayerId={videoItem.shortId}
                        changeActivePlayer={handleActivePlayerChange}
                        activePlayerId={activePlayerId}
                      />
                    </div>
                  </div>
                )
              },
            )

            return (
              <div key={reportItem.id} className={styles.videoItem}>
                {videoList}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default VideosFlowView
