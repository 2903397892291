/**
 *
 *
 * @author fuyg
 * @date  2020-06-08
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import { getAppearYears } from 'src/pages/home/apis/newsAppearYears'
import { getYearsFromStamp } from 'src/pages/home/utils/time'

interface NewsAppearYearsState {
  loading: boolean
  error: string
  appearYears: string[]
}

const initialState: NewsAppearYearsState = {
  loading: false,
  error: '',
  appearYears: [],
}

const newsAppearYearsSlice = createSlice({
  name: 'newsAppearYearsSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<number[]>): void {
      state.loading = false
      state.appearYears = getYearsFromStamp(action.payload)
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = newsAppearYearsSlice.actions

export default newsAppearYearsSlice.reducer

export const fetchAppearYears = (): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const appearYears = await getAppearYears()
    dispatch(getDataSuccess(appearYears))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
