/**
 *
 * @author yangxin
 * @date  2020-06-12
 */

const docType = {
  HTML: 'hmtl',
  WORD: 'word',
  PDF: 'pdf',
  EXCEL: 'excel',
  XBRL: 'xbrl',
}

function shouldOpenNewPage(url: string): boolean {
  const isHtml = url.includes(`.${docType.HTML}`)
  const isPdf = url.includes(`.${docType.PDF}`)
  if (isHtml || isPdf) {
    return true
  }
  return false
}
export { docType, shouldOpenNewPage }
