/**
 *
 *
 * @author fuyg
 * @date  2020-06-03
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import { getAppearYears } from 'src/pages/home/apis/appearYears'
import { getYearsFromStamp } from 'src/pages/home/utils/time'

interface AppearYearsState {
  loading: boolean
  error: string
  years: string[]
}

const initialState: AppearYearsState = {
  loading: false,
  error: '',
  years: [],
}

const appearYearsSlice = createSlice({
  name: 'appearYears',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<number[]>): void {
      state.loading = false
      const { payload } = action
      if (payload && payload.length >= 0) {
        state.years = getYearsFromStamp(action.payload)
      }
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = appearYearsSlice.actions

export default appearYearsSlice.reducer

export const fetchAppearYears = (): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getAppearYears()
    dispatch(getDataSuccess(data))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
