/**
 *
 * @author yangxin
 * @date  2020-06-11
 */

import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'
import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/SECFilings`

export interface RequestParams {
  formType?: string
  page?: number
  pageSize?: number
  startDate: string
  endDate: string
}

interface RawFilingItem {
  dateStamp: number
  desc: string
  excelUrl: string
  formType: string
  htmlUrl: string
  pdfUrl: string
  wordUrl: string
  xbrlDocUrl: string
}

interface RawSECFilingData {
  totalCount: number
  list: RawFilingItem[]
}

export interface FilingItem extends RawFilingItem {
  shortId: string
}

export interface SECFilingsData {
  totalCount: number
  list: FilingItem[]
}

function parseFilingItem(item: RawFilingItem): FilingItem {
  return {
    ...item,
    shortId: shortid.generate(),
  }
}

function parseResponseData(data: RawSECFilingData): SECFilingsData {
  if (!data || !data.list.map) {
    return {
      totalCount: 0,
      list: [],
    }
  }
  const parsedData = {
    totalCount: data.totalCount,
    list: data.list.map((filingItem) => {
      return parseFilingItem(filingItem)
    }),
  }
  return parsedData
}

async function getSECFilings(params: RequestParams): Promise<SECFilingsData> {
  const url = buildUrl(theApi, {
    page: (params && params.page ? params.page : 1).toString(),
    pageSize: (params && params.pageSize ? params.pageSize : 20).toString(),
    startDate: params.startDate,
    endDate: params.endDate,
  })
  const response = await axios.get(url)
  return parseResponseData(response.data.data as RawSECFilingData)
}

export { getSECFilings }
