import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'src/styles/antd/custom/index.scss'
import './index.scss'

import App from './App'
// import * as serviceWorker from './serviceWorker'
import store from 'src/pages/home/store'
import ScrollToTop from 'src/components/ScrollToTop'

function shouldScroll(pathname: string, previousPathname: string): boolean {
  if (
    pathname.indexOf('/introVideos/') > -1 &&
    previousPathname.indexOf('/introVideos/') > -1
  ) {
    return false
  }
  return true
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      <ScrollToTop shouldScroll={shouldScroll} />
      <App />
    </HashRouter>
  </Provider>,

  // </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
