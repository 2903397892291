/**
 *
 *
 * @author yangxin
 * @date  2020-06-05
 */
import { axios, API_PREFIX } from 'src/http'
import { RawVideoItem, VideoItem } from './recentInvestorEvents'

import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/report/introVideos`
interface RawReportWithVideo {
  id: string
  title: string
  videoList: RawVideoItem[]
}
export interface ReportWithVideo {
  id: string
  title: string
  videoList: VideoItem[]
}

function parseResponseData(
  reportWithVideoList: RawReportWithVideo[],
): ReportWithVideo[] {
  const list = reportWithVideoList.map(
    (reportWithVideo: RawReportWithVideo) => {
      const videoList = reportWithVideo.videoList
      const videoListWithId = videoList.map((video) => {
        return {
          ...video,
          shortId: shortid.generate(),
        }
      })
      return {
        id: reportWithVideo.id,
        title: reportWithVideo.title,
        videoList: videoListWithId,
      }
    },
  )
  return list
}

async function getReportWithVideo(): Promise<ReportWithVideo[]> {
  const url = theApi
  const response = await axios.get(url)
  const parsed = parseResponseData(response.data.data as RawReportWithVideo[])
  return parsed
}

export { getReportWithVideo }
