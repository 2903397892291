import React, { ReactElement, useMemo } from 'react'
import { renderRoutes } from 'react-router-config'
import { useLocation } from 'react-router-dom'
import routes from './router/routes'
import './App.scss'

import TopNavigation from 'src/components/TopNavigation'
import { BottomBar } from 'src/components/BottomBar'

function App(): ReactElement {
  const location = useLocation()
  const isUnsubscribe = useMemo((): boolean => {
    return location.pathname.includes('unsubscribe')
  }, [location.pathname])
  return (
    <div className="app">
      <TopNavigation showNavigation={!isUnsubscribe} />
      <div className="empty" />
      <div className="content">{renderRoutes(routes)}</div>

      <BottomBar showToolBar={!isUnsubscribe} />
    </div>
  )
}

export default App
