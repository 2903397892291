/**
 *
 * @author yangxin
 * @date  2020-06-03
 */

import React, { ReactElement } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styles from './SideNavigation.module.scss'
import { scrollToTop } from 'src/utils/scroll'

export interface NavItem {
  id: string
  name: string
  routeLink: string
}

export interface SideNavigationProps extends RouteComponentProps {
  navList: NavItem[]
}

function SideNavigation(props: SideNavigationProps): ReactElement {
  const { navList, location } = props
  const pathname = location.pathname
  const goPage = (path: string): void => {
    scrollToTop()
    props.history.push(path)
  }
  const navItemList = navList.map((navItem) => {
    return (
      <div
        className={`${styles.item} ${
          pathname.includes(navItem.routeLink) ? styles.active : ''
        }`}
        key={navItem.id}
      >
        <a onClick={(): void => goPage(navItem.routeLink)}>{navItem.name}</a>
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.content}>{navItemList}</div>
    </div>
  )
}

export default withRouter(SideNavigation)
