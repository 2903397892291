/**
 *
 * @author fuyg
 * @date  2020-05-26
 */

import { combineReducers } from '@reduxjs/toolkit'

import stockReducer from './slices/stock'
import recentNewsReducer from './slices/recentNews'
import recentInvestorEventsReducer from './slices/recentInvestorEvents'
import appearYearsReducer from './slices/appearYears'
import yearReport from './slices/yearReport'
import reportDetail from './slices/reportDetail'
import businessIntroVideos from './slices/businessIntroVideos'
import news from './slices/news'
import newsAppearYears from './slices/newsAppearYears'
import newsDetail from './slices/newsDetail'
import operatingData from './slices/operatingData'
import financialSummary from './slices/financialSummary'
import subscribe from './slices/subscribe'
import annualReport from './slices/annualReport'
import filingsAppearYears from './slices/filingsAppearYears'
import SECFilings from './slices/SECFilings'
import financialConfig from './slices/financialConfig'
import unsubscribe from './slices/unsubscribe'

const rootReducer = combineReducers({
  stock: stockReducer,
  recentNews: recentNewsReducer,
  recentInvestorEvents: recentInvestorEventsReducer,
  appearYears: appearYearsReducer,
  yearReport,
  reportDetail,
  businessIntroVideos,
  news,
  newsAppearYears,
  newsDetail,
  operatingData,
  financialSummary,
  subscribe,
  annualReport,
  filingsAppearYears,
  SECFilings,
  financialConfig,
  unsubscribe,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
