import routePath from 'src/pages/home/router/routePath'

import Overview from '../views/Overview'
import BusinessOverview from '../views/BusinessOverview'
import InverstorFAQs from '../views/InverstorFAQs'
import OverviewIntro from '../views/OverviewIntro'
import { LongTermView } from '../views/LongTermView'
import { CompanyView } from '../views/CompanyView'
import { CompanyProfileView } from '../views/CompanyProfileView'
import { ManagementView } from '../views/ManagementView'
import { DirectorsView } from '../views/DirectorsView'
import { InvestorEventsView } from '../views/InvestorEventsView'
import { NewsView } from '../views/NewsView'
import { CalendarView } from '../views/CalendarView'
import { NewsEvents } from '../views/NewsEvents'
import { Resources } from '../views/Resources'
import { IntroVideosView } from '../views/IntroVideosView'
// import { ReportIntroVideosView } from '../views/ReportIntroVideosView'
import { VideosFlowView } from '../views/VideosFlowView'
import { RouteConfig } from 'react-router-config'
import { InvestorEventDetailView } from '../views/InvestorEventDetailView'
import { StockInfoView } from '../views/StockInfoView'
import { AnalystCoverage } from '../views/AnalystCoverage'
import { StockInfo } from '../components/StockInfo'
import { ContactView } from '../views/ContactView'
import { EmailAlert } from '../views/EmailAlert'
import { NewsDetailView } from '../views/NewsDetailView'
import { Governance } from '../views/Governance'
import { BoardCommittees } from '../views/BoardCommittees'
import { FinancialView } from '../views/FinancialView'
// import { FinancialDataView } from '../views/FinancialDataView'
import { AnnualReportView } from '../views/AnnualReportView'
import { SECFilingView } from '../views/SECFilingView'
import { QuanterlyEarnings } from '../views/QuanterlyEarnings'
import { CorporateGovernance } from '../views/CorporateGovernance'
import { UnsubscribeView } from '../views/UnsubscribeView'
import { LiveBroadcastView } from '../views/LiveBroadcastView'

const routes: RouteConfig[] = [
  { path: '/', exact: true, component: OverviewIntro },
  {
    path: '/overview',
    component: Overview,
    routes: [
      {
        path: routePath.OVERVIEW_BUSINESS,
        component: BusinessOverview,
      },
      {
        path: routePath.OVERVIEW_LONGTERM,
        component: LongTermView,
      },
      {
        path: routePath.OVERVIEW_INVESTOR,
        component: InverstorFAQs,
      },
    ],
  },
  {
    path: routePath.COMPANY,
    component: CompanyView,
    routes: [
      {
        path: routePath.COMPANY_PROFILE,
        exact: true,
        component: CompanyProfileView,
      },
      {
        path: routePath.COMPANY_MANAGEMENT,
        component: ManagementView,
      },
      {
        path: routePath.COMPANY_DIRECTORS,
        component: DirectorsView,
      },
    ],
  },
  {
    path: routePath.EVENT_INVESTOR_DETAIL,
    component: InvestorEventDetailView,
  },
  {
    path: routePath.EVENT_NEWS_DETAIL,
    component: NewsDetailView,
  },
  {
    path: routePath.EVENT,
    component: NewsEvents,
    routes: [
      {
        path: routePath.EVENT_INVESTOR,
        exact: true,
        component: InvestorEventsView,
      },
      {
        path: routePath.EVENT_CALENDAR,
        component: CalendarView,
      },
      {
        path: routePath.EVENT_NEWS,
        component: NewsView,
      },
    ],
  },
  {
    path: routePath.STOCK_INFO,
    component: StockInfoView,
    routes: [
      {
        path: routePath.STOCK_INFO_QUOTE_CHART,
        component: StockInfo,
      },
      {
        path: routePath.STOCK_INFO_ANALYST_COVERAGE,
        component: AnalystCoverage,
      },
    ],
  },
  {
    path: routePath.GOVERNANCE,
    component: Governance,
    routes: [
      {
        path: routePath.GOVERNANCE_CORPORATE,
        component: CorporateGovernance,
      },
      {
        path: routePath.GOVERNANCE_COMMITTEES,
        component: BoardCommittees,
      },
    ],
  },
  {
    path: routePath.RESOURCE,
    component: Resources,
    routes: [
      // {
      //   path: routePath.RESOURCE_INTRO_VIDEOS,
      //   component: IntroVideosView,
      //   routes: [
      //     {
      //       path: routePath.REPORT_INTRO_VIDEOS,
      //       component: ReportIntroVideosView,
      //     },
      //   ],
      // },
      {
        path: routePath.RESOURCE_CONTACT,
        exact: true,
        component: ContactView,
      },
      {
        path: routePath.RESOURCE_EMAIL,
        exact: true,
        component: EmailAlert,
      },
    ],
  },
  {
    path: routePath.INTRO_VIDEOS,
    component: IntroVideosView,
    routes: [
      {
        path: routePath.REPORT_INTRO_VIDEOS,
        component: VideosFlowView,
      },
    ],
  },
  {
    path: routePath.FINANCIAL,
    component: FinancialView,
    routes: [
      {
        path: routePath.FINANCIALS_QUANTERLY_EARNINGS,
        component: QuanterlyEarnings,
      },
      // 暂时注释，不要删除
      // {
      // path: routePath.FINANCIALS_DATA,
      // component: FinancialDataView,
      // },
      {
        path: routePath.FINANCIALS_ANNUAL_REPORT,
        component: AnnualReportView,
      },
      {
        path: routePath.FINANCIALS_SEC,
        component: SECFilingView,
      },
    ],
  },
  {
    path: routePath.UNSUBSCRIBE,
    component: UnsubscribeView,
  },
  {
    path: routePath.LIVE_BROADCAST,
    component: LiveBroadcastView,
  },
]

export default routes
