import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  subscribe,
  SubscribeParams,
  SubscribeReponse,
} from 'src/pages/home/apis/subscribe'

interface CommonState {
  loading: boolean
  error: string
  data: SubscribeReponse
}
const initialState: CommonState = {
  loading: false,
  error: '',
  data: { code: -1, message: '' },
}

const subscribeSlice = createSlice({
  name: 'subscribeSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<SubscribeReponse>): void {
      state.loading = false
      state.data = action.payload
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = subscribeSlice.actions

export default subscribeSlice.reducer

export const subscribeEmail = (params: SubscribeParams): AppThunk => async (
  dispatch,
): Promise<SubscribeReponse | undefined> => {
  try {
    dispatch(getDataStart())
    const data = await subscribe(params)
    dispatch(getDataSuccess(data))
    return data
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
