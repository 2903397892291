/**
 *
 *
 * @author yangxin
 * @date  2020-07-23
 */

import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'

const theSubscribeStatusApi = `${API_PREFIX}/pub/subscribe/subscribeStatus`
const theUnsubscribeApi = `${API_PREFIX}/pub/subscribe/unsubscribe`

export interface SubscribeStatusParams {
  code: string
}

export interface UnsubscribeParams {
  code: string
}

export interface SubscribeStatusResponse {
  data: boolean
}

export interface UnsubscribeResponse {
  code: number
  message: string
}

async function getStatus(
  params: SubscribeStatusParams,
): Promise<SubscribeStatusResponse> {
  const search = {
    code: params.code,
  }
  const url = buildUrl(theSubscribeStatusApi, search)
  const response = await axios.get(url)
  const data = response.data
  return data
}

async function unsubscribe(
  params: UnsubscribeParams,
): Promise<UnsubscribeResponse> {
  const search = {
    code: params.code,
  }
  const url = buildUrl(theUnsubscribeApi, search)
  const response = await axios.get(url)
  const data = response.data
  return data
}

export { getStatus, unsubscribe }
