/**
 *
 * @author yangxin
 * @date  2020-12-02
 */

import React, { ReactElement, useEffect, useState } from 'react'
import styles from './LiveBroadcastView.module.scss'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

function LiveBroadcastView(): ReactElement {
  // eslint-disable-next-line
  const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (videoNode) {
      // video 默认不支持 RTMP 格式的视频播放, 且目前chrome不再支持flash, 因此不会考虑用 RTMP 格式的直播地址
      videojs(videoNode, {
        controls: true,
        sources: [
          {
            src:
              'https://kevideohpullbd.ydstatic.com/pushstation/d875f159beb0_screen.m3u8',
            type: 'application/x-mpegURL',
          },
        ],
      })
    }
  }, [videoNode])
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <div data-vjs-player>
          <video
            id="my-video"
            webkit-playsinline="true"
            playsInline={true}
            preload="auto"
            ref={(node): void => {
              setVideoNode(node)
            }}
            className="video-js vjs-fluid"
          ></video>
        </div>
      </span>
    </div>
  )
}

export default LiveBroadcastView
