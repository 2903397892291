/**
 *
 * @author bjhuangdy
 * @date  2021-01-20
 */

import React, { ReactElement } from 'react'
import styles from './AnalystCoverage.module.scss'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'
import { Table } from 'antd'
function AnalystCoverage(): ReactElement {
  const columns = [
    {
      title: 'Firm',
      dataIndex: 'firm',
      width: '50%',
    },
    {
      title: 'Analyst',
      dataIndex: 'analyst',
      width: '50%',
    },
  ]

  interface DataType {
    key: React.Key
    firm: string
    analyst: string
  }

  const data: DataType[] = [
    {
      key: '1',
      firm: 'BOCI',
      analyst: 'Yi Lu',
    },
    {
      key: '2',
      firm: 'BOCOM International',
      analyst: 'Mengqi Sun',
    },
    {
      key: '3',
      firm: 'CCB',
      analyst: 'Cathy Chan',
    },
    {
      key: '4',
      firm: 'China International Capital Corporation',
      analyst: 'Liping Zhao',
    },
    {
      key: '5',
      firm: 'Citi',
      analyst: 'Brian Gong',
    },
    {
      key: '6',
      firm: 'CITIC',
      analyst: 'Ya Jiang',
    },
    {
      key: '7',
      firm: 'CMS',
      analyst: 'Tommy Wong',
    },
    {
      key: '8',
      firm: 'Daiwa',
      analyst: 'Candis Chan',
    },
    {
      key: '9',
      firm: 'GF Securities',
      analyst: 'Shi Kuang',
    },
    {
      key: '10',
      firm: 'Huafu  Securities',
      analyst: 'Shunan Lai',
    },
    {
      key: '11',
      firm: 'Huatai Securities',
      analyst: 'Lulu Xia',
    },
    {
      key: '12',
      firm: 'Jefferies',
      analyst: 'Thomas Chong',
    },
    {
      key: '13',
      firm: 'Macquarie',
      analyst: 'Linda Huang',
    },
    {
      key: '14',
      firm: 'Nomura',
      analyst: 'Jialong Shi',
    },
    {
      key: '15',
      firm: 'Orient Securities',
      analyst: 'Wenqian Xiang',
    },
  ]

  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.analystConverage} />
        <div className={styles.description}>
          Below is a list of individual analysts that we are aware of that
          currently follow Youdao,Inc.
        </div>
        <div className={styles.analystConverageTable}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <p>
          DISCLAIMER: Youdao, Inc. is followed by the analysts listed above.
          Please note that any opinions, estimates or forecasts regarding
          Youdao, Inc. is performanced by these analysts are theirs alone and do
          not represent opinions, forecasts or predictions of Youdao, Inc. or
          its management. Youdao, Inc. does not by its reference above or
          distribution imply its endorsement of or concurrence with such
          information, conclusions or recommendations.
        </p>
      </span>
    </div>
  )
}

export default AnalystCoverage
