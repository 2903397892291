/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement } from 'react'
import styles from './ReadMoreButton.module.scss'
import { ReactComponent as ArrowIcon } from 'src/components/TopNavigation/arrow.svg'
import { Link } from 'react-router-dom'
import routePath, { RoutePathName } from 'src/pages/home/router/routePath'

interface ReadMoreButtonProps {
  routeName: RoutePathName
}

function ReadMoreButton(props: ReadMoreButtonProps): ReactElement {
  const realHref = routePath[props.routeName]
  return (
    <Link className={styles.container} to={realHref}>
      <span className={styles.text}>Read More</span>
      <ArrowIcon className={styles.icon} />
    </Link>
  )
}

export default ReadMoreButton
