/**
 * axios 相关封装
 *
 * @author fuyg
 * @date  2020-05-28
 */

import axios, { AxiosInstance } from 'axios'
import { message } from 'antd'

const instance: AxiosInstance = axios.create({
  // @see https://github.com/axios/axios
  withCredentials: process.env.NODE_ENV !== 'production',
})

instance.interceptors.response.use(
  function (response) {
    if (response.data.code == 1003 || response.data.code == 1006) {
      const { pathname, origin } = window.location
      const loginUrl = origin + pathname + '#/login'
      window.location.replace(loginUrl)
    }
    return response
  },
  function (error) {
    // 对响应错误做点什么
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          message.error('错误的请求')
          break
        case 500:
          message.error('服务器端出错')
          break
        case 401:
          message.error('权限校验错误')
          break
        default:
          message.error('网络错误')
      }
    }
    return Promise.reject(error)
  },
)

export default instance
