/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './AnnualReportView.module.scss'
import { fetchAnnualReports } from 'src/pages/home/store/slices/annualReport'
import { RootState } from 'src/pages/home/store/rootReducer'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { AnnualReportItem } from 'src/pages/home/components/AnnualReportItem'
import { navText } from 'src/utils/navText'

function AnnualReportView(): ReactElement {
  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAnnualReports())
  }, [])

  // 绑定数据
  const { reportList } = useSelector((state: RootState) => {
    return {
      reportList: state.annualReport.reportList,
    }
  }, shallowEqual)
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.annualReports} />
        <div className={styles.mainContent}>
          {reportList.map((reportItem) => {
            return (
              <AnnualReportItem key={reportItem.shortId} report={reportItem} />
            )
          })}
        </div>
      </span>
    </div>
  )
}

export default AnnualReportView
