/**
 *
 *
 * @author fuyg
 * @date  2020-06-03
 */
import { axios, API_PREFIX } from 'src/http'
import {
  RawInvestorEvent,
  parseResponseData,
  InvestorEvent,
} from './recentInvestorEvents'
import { buildUrl } from 'src/utils/urlSearch'

export interface YearReportParams {
  startStamp: number
  endStamp: number
}

const theApi = `${API_PREFIX}/pub/report`

async function getYearReport(
  params: YearReportParams,
): Promise<InvestorEvent[]> {
  const url = buildUrl(theApi, {
    startStamp: params.startStamp.toString(),
    endStamp: params.endStamp.toString(),
  })
  const response = await axios.get(url)
  const parsed = parseResponseData(response.data.data as RawInvestorEvent[])
  return parsed
}

export { getYearReport }
