/**
 *  @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 *
 * @author fuyg
 * @date  2020-06-22
 */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from 'src/utils/scroll'

interface ScrollToTopProps {
  shouldScroll?: (pathname: string, previousPathname: string) => boolean
}

function ScrollToTop(props: ScrollToTopProps): null {
  const [previousPathname, setPreviousPathname] = useState('')
  const { pathname } = useLocation()
  const { shouldScroll } = props
  useEffect(() => {
    setPreviousPathname(pathname)
    if (shouldScroll && shouldScroll(pathname, previousPathname) === false) {
      return
    }
    setTimeout(() => {
      scrollToTop()
    }, 100)
  }, [pathname])

  return null
}

export default ScrollToTop
