import React, { ReactElement } from 'react'
import styles from './DirectorsView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'

interface DirectorItem {
  name: string
  position: string
  description: { __html: string }
}
function createMarkup(htmlStr: string): { __html: string } {
  return {
    __html: htmlStr,
  }
}
function DirectorsView(): ReactElement {
  const directorList: DirectorItem[] = [
    {
      name: 'William Lei Ding',
      position: 'Director',
      description: createMarkup(
        'William Lei Ding has served as Youdao’s director since January 2015. Mr. Ding is the founder of NetEase, Inc. (Nasdaq: NTES) and currently serves as a director and the chief executive officer of NetEase. At NetEase, from March 2001 to November 2005, Mr. Ding served as the chief architect, and, from June 2001 to September 2001, he served as the acting chief executive officer and acting chief operating officer. From July 1999 to March 2001, Mr. Ding served as the co-chief technology officer of NetEase, and from July 1999 to April 2000, he also served as NetEase’s interim chief executive officer. Mr. Ding established Guangzhou NetEase Computer System Co., Ltd. and Shanghai EaseNet Network Technology Co., Ltd., in May 1997 and January 2008, respectively. Mr. Ding holds a bachelor of science degree in communication technology from the University of Electronic Science and Technology of China.',
      ),
    },
    {
      name: 'Feng Zhou',
      position: 'Chief Executive Officer and Director',
      description: createMarkup(
        'Feng Zhou currently serves as Youdao’s Chief Executive Officer and has served as a director since April 2018. Prior to joining Youdao in 2007, Dr. Zhou served as a software engineer at ChinaRen Inc. where he led the development of its internet email system. Dr. Zhou received his bachelor’s degree and master’s degree in computer science from Tsinghua University and received a Ph.D. in computer science from the University of California, Berkeley.',
      ),
    },
    {
      name: 'Harry Heung Yeung Shum',
      position: 'Independent Director',
      description: createMarkup(
        'Harry Heung Yeung Shum has served as Youdao’s independent director since its initial public offering. Mr. Shum joined Microsoft Research in 1996 as a researcher based in Redmond, Washington. In 1998, he joined Microsoft Research Asia (formerly known as Microsoft Research China) in Beijing, China as one of the founding members and subsequently spent nine years there, becoming the managing director of Microsoft Research Asia. From 2007 to 2013, Mr. Shum served as the corporate vice president in charge of Bing search product development. Since 2013, he has served as the executive vice president of Microsoft Corporation. Mr. Shum received his Ph.D. in robotics from Carnegie Mellon University.',
      ),
    },
    {
      name: 'Jimmy Lai',
      position: 'Independent Director',
      description: createMarkup(
        'Jimmy Lai has served as Youdao’s independent director since  October 2019. Mr. Lai has served as an independent director of FinVolution Group (NYSE: FINV) since November 2017, an independent director of Zepp Health Corporation (NYSE: ZEPP) since February 2018 and the chief financial officer of Acepodia Inc since March 2021. Previously, Mr. Lai served as the chief financial officers of China Online Education Group (NYSE: COE) from 2015 to 2018, Chukong Technologies Corp. from 2013 to 2015, and Gamewave Corporation from 2011 to 2013. Prior to that, Mr. Lai served as the chief financial officer of several public companies listed in the United States and in various finance-related roles in other companies. Mr. Lai received his bachelor’s degree in Statistics from the National Cheng Kung University in Taiwan and his MBA from the University of Texas at Dallas. Mr. Lai is a certified public accountant licensed in the State of Texas.',
      ),
    },
  ]
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.boardOfDirectors} />
        <div className={styles.mainContent}>
          {directorList.map((director) => {
            return (
              <div key={director.name} className={styles.directorItem}>
                <div className={styles.name}>{director.name}</div>
                <div className={styles.position}>{director.position}</div>
                <div className={styles.description}>
                  <article
                    dangerouslySetInnerHTML={director.description}
                  ></article>
                </div>
              </div>
            )
          })}
        </div>
      </span>
    </div>
  )
}

export default DirectorsView
