/**
 *
 * @author yangxin
 * @date  2020-06-05
 */

import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from './IntroVideosView.module.scss'
import { fetchReportWithVideo } from 'src/pages/home/store/slices/businessIntroVideos'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'

import { BigTitle } from 'src/pages/home/components/BigTitle'

function IntroVideosView(
  props: RouteConfigComponentProps<{ reportId: string }>,
): ReactElement {
  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchReportWithVideo())
  }, [])

  // 绑定数据

  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title="Videos" />
        <div className={styles.mainContent}>
          {renderRoutes(props.route?.routes)}
        </div>
      </span>
    </div>
  )
}

export default IntroVideosView
