/**
 *
 * @author fuyg
 * @date  2020-06-02
 */
import React, { ReactElement } from 'react'
import styles from './RecentNewsItem.module.scss'
import { NewsSummary } from 'src/pages/home/apis/news'
import { toNewYorkSimple, toNewYorkEst } from 'src/utils/time'
import { Link } from 'react-router-dom'
import { buildRoutePath } from 'src/utils/buildRoutePath'
import ClampLines from 'react-clamp-lines'
interface RecentNewsItemProps {
  news: NewsSummary
  useShortTime: boolean
  styleFixed?: boolean
}

interface RecentNewsItemState {
  stateName: string
}

function RecentNewsItem(props: RecentNewsItemProps): ReactElement {
  const { news, useShortTime, styleFixed = false } = props

  const time = useShortTime
    ? toNewYorkSimple(news.dateStamp)
    : toNewYorkEst(news.dateStamp)
  const route = buildRoutePath('EVENT_NEWS_DETAIL', {
    newsId: news.id,
  })

  return (
    <div className={`${styles.container} ${styleFixed ? 'styleFixed' : null}`}>
      <h3 className={styles.title}>
        <Link to={route} target="_blank">
          {styleFixed ? (
            <ClampLines
              id="really-unique-id"
              text={news.title}
              lines={2}
              buttons={false}
              ellipsis="..."
            ></ClampLines>
          ) : (
            news.title
          )}
        </Link>
      </h3>
      <p className={styles.time}>{time}</p>
    </div>
  )
}

RecentNewsItem.defaultProps = {
  useShortTime: true,
}

export default RecentNewsItem
