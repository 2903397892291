/**
 *
 * @author fuyg
 * @date  2020-06-09
 */

import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './EventCalendar.module.scss'
import { fetchYearReport } from 'src/pages/home/store/slices/yearReport'
import { RootState } from 'src/pages/home/store/rootReducer'
import moment, { Moment } from 'moment'
import { InvestorEvent } from 'src/pages/home/apis/recentInvestorEvents'
import { Calendar, Button } from 'antd'
// import { CalendarMode, HeaderRender, } from 'antd/lib/calendar/generateCalendar'
import { InvestorEventItem } from 'src/pages/home/components/InvestorEventItem'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { toNewYorkMonth } from 'src/utils/time'
import { EventCalendarHint } from 'src/pages/home/components/EventCalendarHint'

function EventCalendar(): ReactElement {
  const [month, setMonth] = useState(moment().valueOf())
  const [selectedReports, setSelectedReports] = useState([] as InvestorEvent[])
  const [activeDate, setActiveDate] = useState(null)

  const computedYear = useMemo((): number => {
    // eslint-disable-next-line
    const year = moment(month).year()
    return year
  }, [month])

  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    // console.log('use effect, dispatch fetchYearReport: ', computedYear)
    const startStamp = moment()
      .year(Number(computedYear))
      .startOf('year')
      .add(13, 'hour')
      .valueOf()
    const endStamp = moment()
      .year(Number(computedYear))
      .endOf('year')
      .add(13, 'hour')
      .valueOf()
    dispatch(
      fetchYearReport({
        // year: `${computedYear}`,
        startStamp,
        endStamp,
      }),
    )
  }, [computedYear])

  useEffect(() => {
    setTimeout(() => {
      const cells = document.getElementsByClassName('ant-picker-cell')
      for (let i = 0; i < cells.length; i++) {
        cells[i].getAttribute('title')
        cells[i].className = cells[i].className.replace('selected-cell', '')
        const isActiveCell =
          cells[i].getAttribute('title') ===
          moment(activeDate).format('YYYY-MM-DD')
        if (isActiveCell) {
          cells[i].className = cells[i].className + ' selected-cell'
        }
      }
    }, 0)
  }, [activeDate, month])

  // 绑定数据
  const { report } = useSelector((state: RootState) => {
    return {
      report: state.yearReport.report,
    }
  }, shallowEqual)

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isEventDate(date: any): boolean {
    const theDate = date as Moment
    const isMatch = report.some((item: InvestorEvent) => {
      return moment(item.time)
        .tz('America/New_York')
        .isSame(theDate.tz('America/New_York'), 'day')
    })
    return isMatch ? true : false
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isDisabledDate(date: any): boolean {
    return isEventDate(date) ? false : true
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSelect(date: any /*, mode: CalendarMode*/): void {
    if (isEventDate(date)) {
      const selected = report.filter((item: InvestorEvent): boolean => {
        return moment(item.time)
          .tz('America/New_York')
          .isSame(date.tz('America/New_York'), 'day')
      })
      setSelectedReports(selected)
      setActiveDate(date)
    }
  }

  function onPreviousMonth(): void {
    const value = moment(month).subtract(1, 'month')
    setMonth(value.valueOf())
  }

  function onNextMonth(): void {
    const value = moment(month).add(1, 'month')
    setMonth(value.valueOf())
  }

  return (
    <div className={styles.container}>
      <div className={styles.calendar}>
        <Calendar
          fullscreen={false}
          value={moment(month)}
          onSelect={onSelect}
          disabledDate={isDisabledDate}
          headerRender={({
            value /*, type, onChange, onTypeChange */,
          }): ReactElement => {
            return (
              <div className={styles.header}>
                <Button
                  shape="circle"
                  icon={<ArrowIcon />}
                  onClick={onPreviousMonth}
                />
                <div>{toNewYorkMonth(value as Moment)}</div>
                <Button
                  shape="circle"
                  icon={<ArrowIcon className={styles.right} />}
                  onClick={onNextMonth}
                />
              </div>
            )
          }}
          dateCellRender={(value): ReactNode => {
            return isEventDate(value) ? <div className="dot"></div> : ''
          }}
        />
        <div className={styles.hint}>
          <div className={styles.dot}></div>
          <p>Release Date</p>
        </div>
      </div>
      <div className={styles.event}>
        {selectedReports && selectedReports.length ? (
          selectedReports.map((item) => {
            return <InvestorEventItem key={item.shortId} event={item} />
          })
        ) : (
          <div className={styles.clickHint}>
            <EventCalendarHint />
          </div>
        )}
      </div>
    </div>
  )
}

export default EventCalendar
