/**
 *
 * @author yangxin
 * @date  2020-05-26
 */

import React, { ReactElement } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import routePath from 'src/pages/home/router/routePath'
import styles from './ToolBar.module.scss'
import { ReactComponent as PrintIcon } from './print.svg'
import { ReactComponent as EmailIcon } from './email.svg'
import { scrollToTop } from 'src/utils/scroll'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ToolBarProps extends RouteComponentProps {
  // propName: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ToolBarState {
  // stateName: string
}

class ToolBar extends React.Component<ToolBarProps, ToolBarState> {
  /***************************************************************************
   *  static properties and methods
   **************************************************************************/

  static defaultProps = {
    // TODO
  }

  constructor(props: ToolBarProps) {
    super(props)

    this.state = {
      stateName: 'TODO',
    }

    this.printPage = this.printPage.bind(this)
    this.goEmailAlert = this.goEmailAlert.bind(this)
  }

  render(): ReactElement {
    return (
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <button onClick={this.printPage} className={styles.btn}>
            <PrintIcon className={styles.icon} />
            Print Page
          </button>
          <button onClick={this.goEmailAlert} className={styles.btn}>
            <EmailIcon className={styles.icon} />
            Email Alerts
          </button>
        </div>
        <div className={styles.mask} />
      </div>
    )
  }

  /***************************************************************************
   *  custom methods
   **************************************************************************/

  nameMethod(): void {
    // TODO
  }

  printPage(): void {
    window.print()
  }

  goEmailAlert(): void {
    this.props.history.push(routePath.RESOURCE_EMAIL)
    scrollToTop()
  }
  /***************************************************************************
   * lifecycle methods
   **************************************************************************/

  // componentDidMount() {}

  // componentDidUpdate(prevProps, prevState, snapshot) {}

  // componentWillUnmount() {}

  /* rarely used lifecycle methods */

  // static getDerivedStateFromProps(props, state) {}

  // shouldComponentUpdate(nextProps, nextState) {}

  // getSnapshotBeforeUpdate(prevProps, prevState) {}

  /* error boundaries */

  // static getDerivedStateFromError(error) {}

  // componentDidCatch(error, info) {}

  /* legacy lifecycle methods */

  // UNSAFE_componentWillMount() {}

  // UNSAFE_componentWillReceiveProps(nextProps) {}

  // UNSAFE_componentWillUpdate(nextProps, nextState) {}
}

export default withRouter(ToolBar)
