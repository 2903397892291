/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import React, { ReactElement } from 'react'
import styles from './CompanyView.module.scss'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import routePath from 'src/pages/home/router/routePath'
import { navText } from 'src/utils/navText'
import shortid from 'shortid'

import { SideNavigation, NavItem } from 'src/components/SideNavigation'

function CompanyView(props: RouteConfigComponentProps): ReactElement {
  const navList: NavItem[] = [
    {
      id: shortid.generate(),
      name: navText.companyProfile,
      routeLink: routePath.COMPANY_PROFILE,
    },
    {
      id: shortid.generate(),
      name: navText.management,
      routeLink: routePath.COMPANY_MANAGEMENT,
    },
    {
      id: shortid.generate(),
      name: navText.boardOfDirectors,
      routeLink: routePath.COMPANY_DIRECTORS,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <SideNavigation navList={navList} />
      </div>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default CompanyView
