/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AnnualReport,
  getAnnualReports,
} from 'src/pages/home/apis/annualReport'

import { AppThunk } from 'src/pages/home/store'

interface RecentInvestorEventsState {
  loading: boolean
  error: string
  reportList: AnnualReport[]
}

const initialState: RecentInvestorEventsState = {
  loading: false,
  error: '',
  reportList: [],
}

const slice = createSlice({
  name: 'annualReport',
  initialState,
  reducers: {
    getEventsStart(state): void {
      state.loading = true
    },
    getEventsSuccess(state, action: PayloadAction<AnnualReport[]>): void {
      state.loading = false
      state.reportList = action.payload
    },
    getEventsError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getEventsStart,
  getEventsError,
  getEventsSuccess,
} = slice.actions

export default slice.reducer

export const fetchAnnualReports = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch(getEventsStart())
      const data = await getAnnualReports()
      dispatch(getEventsSuccess(data))
    } catch (err) {
      dispatch(getEventsError(err as string))
    }
  }
}
