/**
 *
 *
 * @author yangxin
 * @date  2020-06-12
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  RequestParams,
  FilingItem,
  SECFilingsData,
  getSECFilings,
} from 'src/pages/home/apis/SECFilings'

interface FilingsState {
  loading: boolean
  error: string
  list: FilingItem[]
  totalCount: number
}

const initialState: FilingsState = {
  loading: false,
  error: '',
  list: [],
  totalCount: 0,
}

const secFilingsSlice = createSlice({
  name: 'secFilingsSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<SECFilingsData>): void {
      state.loading = false
      state.totalCount = action.payload.totalCount
      state.list = action.payload.list
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = secFilingsSlice.actions

export default secFilingsSlice.reducer

export const fetchSECFilings = (params: RequestParams): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const FilingsData = await getSECFilings(params)
    dispatch(getDataSuccess(FilingsData))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
