/**
 *
 * @author fuyg
 * @date  2020-06-01
 */

import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'
// import shortid from 'shortid'
import { RawNewsSummary, NewsSummary, parseNewsSummary } from './news'

const theApi = `${API_PREFIX}/pub/news/recent`

export interface RecentNewsRequestParams {
  count: number
  type: string
}

async function getRecentNews(
  params?: RecentNewsRequestParams,
): Promise<NewsSummary[]> {
  const url = buildUrl(theApi, {
    count: (params && params.count ? params.count : 5).toString(),
    type: (params && params.type ? params.type : 'financial').toString(),
  })
  const response = await axios.get(url)
  const parsed = parseNewsSummary(response.data.data as RawNewsSummary[])
  return parsed
}

export { getRecentNews }
