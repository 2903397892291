/**
 *
 * @author yangxin
 * @date  2020-06-11
 */
import { axios, API_PREFIX } from 'src/http'

const theApi = `${API_PREFIX}/pub/SECFilings/appearYears`

async function getFilingsAppearYears(): Promise<string[]> {
  const url = theApi
  const response = await axios.get(url)
  return response.data.data as string[]
}

export { getFilingsAppearYears }
