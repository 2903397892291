/**
 *
 * @author fuyg
 * @date  2020-05-20
 */

import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { fetchStock } from 'src/pages/home/store/slices/stock'
import { RootState } from 'src/pages/home/store/rootReducer'
import { StockState } from 'src/pages/home/components/StockState'

function StockMarket(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStock())
  }, [])

  const { stock } = useSelector((state: RootState) => {
    return {
      stock: state.stock.stock,
      loading: state.stock.loading,
    }
  }, shallowEqual)

  return <StockState stock={stock} />
}

export default StockMarket
