/**
 *
 * @author fuyg
 * @date  2020-06-04
 */

import React, { ReactElement, useMemo /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './StockState.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { StockResponseData } from 'src/pages/home/apis/stock'
import { formatStock } from 'src/pages/home/utils/stock'
import classnames from 'classnames'

export interface StockStateProps {
  stock: StockResponseData | null
  useBackgrondImage?: boolean
  changeStateStyle?: boolean
}

function StockState(props: StockStateProps): ReactElement {
  const { stock, useBackgrondImage, changeStateStyle = false } = props
  const isLoading = stock ? false : true
  const theStock = useMemo(() => {
    return formatStock(stock)
  }, [stock])

  const content = isLoading ? (
    <span>loading</span>
  ) : (
    <div className={classnames(styles.content, 'stock-state-content')}>
      <div className={styles.price}>
        <p className={styles.code}>NYSE: DAO</p>
        <h3>$ {theStock.current}</h3>
        <p className={styles.change}>
          {theStock.change} ({theStock.changePercent})
        </p>
      </div>
      <div className={styles.state}>
        {changeStateStyle ? (
          <div>
            <p>Volume：{theStock.volume}</p>
            <p>Market Cap：{theStock.marketCap}</p>
          </div>
        ) : (
          <p>
            Volume：{theStock.volume} Market Cap：{theStock.marketCap}
          </p>
        )}

        <p className={styles.hint}>
          Currency in USD. Minimum 15 minutes delayed.
        </p>
      </div>
    </div>
  )

  return (
    <div
      className={classnames(
        styles.container,
        useBackgrondImage ? styles.background : styles['home-stock-container'],
        'stock-state',
      )}
    >
      {content}
    </div>
  )
}

StockState.defaultProps = {
  useBackgrondImage: false,
}

export default StockState
