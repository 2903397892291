/**
 *
 * @author fuyg
 * @date  2020-06-21
 */
import { StockResponseData } from 'src/pages/home/apis/stock'
import { fixNumber } from 'src/utils/number'

interface FormattedStock {
  change: string
  changePercent: string
  current: string
  marketCap: string
  open: string
  range: string
  yearRange: string
  volume: string
}

function formatStock(stock: StockResponseData | null): FormattedStock {
  const formatted: FormattedStock = {
    change: '',
    changePercent: '',
    current: '',
    marketCap: '',
    open: '0',
    range: '',
    yearRange: '',
    volume: '',
  }
  if (stock) {
    const {
      change,
      changePercent,
      current,
      marketCap,
      open,
      rangeLeft,
      rangeRight,
      volume,
      yearRangeLeft,
      yearRangeRight,
    } = stock
    const changeValue =
      change > 0 ? `+${fixNumber(change, 2)}` : `${fixNumber(change, 2)}`
    formatted.change = change === 0 ? '0.00' : changeValue
    const changePercentValue =
      changePercent > 0
        ? `+${fixNumber(changePercent, 2)}%`
        : `${fixNumber(changePercent, 2)}%`
    formatted.changePercent = changePercent === 0 ? '0.00%' : changePercentValue
    formatted.current = `${fixNumber(current, 2)}`
    formatted.marketCap = `\$${fixNumber(marketCap, 1)}B`
    formatted.open = `${fixNumber(open, 2)}`
    formatted.range = `\$${fixNumber(rangeLeft, 2)} - \$${fixNumber(
      rangeRight,
      2,
    )}`
    formatted.yearRange = `\$${fixNumber(yearRangeLeft, 2)} - \$${fixNumber(
      yearRangeRight,
      2,
    )}`
    formatted.volume = `${fixNumber(volume, 1)}K`
  }

  return formatted
}

export { formatStock }
