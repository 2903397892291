import React, { ReactElement } from 'react'
import styles from './InverstorFAQs.module.scss'
import { Collapse } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { BigTitle } from 'src/pages/home/components/BigTitle'

const { Panel } = Collapse

function InverstorFAQs(): ReactElement {
  const faqsData = [
    {
      index: 1,
      question: 'What is Youdao’s business?',
      answerHtml:
        '<p>Youdao was founded in 2006 as part of NetEase, a leading internet technology company in China, dedicated to providing online services centered around content, community, communication and commerce. In 2007, we launched our flagship Youdao Dictionary, which is China’s number one language app in terms of MAUs in the first half of 2019, according to Frost & Sullivan.</p><p>Starting with online knowledge tools, Youdao currently offers a comprehensive suite of learning products and services that are accessible, reliable and trustworthy. Youdao develops and provides a collection of online dictionary, translation and writing tools powered by technologies to address the various needs of users of all ages. Building on the popularity of these online knowledge tools, we have developed a series of online courses, including our flagship online course brand Youdao Premium Courses, and interactive learning apps that allow us to better cover the entire user learning journey. In addition to Youdao Premium Courses, which mainly targets K-12 students, we also offer China University MOOC and NetEase Cloud Classroom for adult users. Our learning products and services cover a wide spectrum of age groups, subject matter, learning goals and areas of interest. Moreover, we are the only company in China that fully integrates smart devices into an effective hybrid online and offline learning experience, with our Youdao Smart Pen, Youdao Dictionary Pen and Youdao Pocket Translator, according to Frost & Sullivan.</p>',
    },
    {
      index: 2,
      question: 'Where are Youdao’s corporate headquarters located?',
      answerHtml: `<p>Our headquarters are located in Beijing.
       Our contact details are:
       Building No. 7, West Zone, Zhongguancun Software Park (Phase II)
       No.10 Xibeiwang East Road, Haidian District
       Beijing 100193, People’s Republic of China
       Website: www.youdao.com
       In addition, Youdao has offices in Hangzhou and other cities in China.</p>`,
    },
    {
      index: 3,
      question: 'How many employees does Youdao have?',
      answerHtml:
        'As of December 31, 2019, Youdao had 1,699 full-time employees.',
    },
    {
      index: 4,
      question:
        'What is Youdao’s ticker symbol and on which exchange does it trade?',
      answerHtml:
        'Youdao, Inc.’s American Depositary Shares (ADSs) have been listed on the New York Stock Exchange since October 25, 2019, under the symbol “DAO.”',
    },
    {
      index: 5,
      question:
        'How many Youdao’s ordinary shares does one American Depositary Share (“ADS”) of Youdao represent?',
      answerHtml:
        'Each ADS of Youdao, Inc. represents one Class A ordinary share.',
    },
    {
      index: 6,
      question: 'When does Youdao’s fiscal year end?',
      answerHtml: 'Youdao’s fiscal year ends on December 31.',
    },
    {
      index: 7,
      question: 'How can I obtain a copy of Youdao’s Annual Report?',
      answerHtml:
        'Annual reports will be available via this website. All SEC documents are also accessible online through the SEC Filings page.',
    },
    {
      index: 8,
      question: 'Who is Youdao’s independent auditor?',
      answerHtml: 'PricewaterhouseCoopers Zhong Tian LLP',
    },
    {
      index: 9,
      question: 'Who is Youdao’s US legal counsel?',
      answerHtml: 'Davis Polk & Wardwell LLP',
    },
    {
      index: 10,
      question: 'Who is Youdao’s Depositary Bank for ADSs?',
      answerHtml: 'The Bank of New York Mellon',
    },
    {
      index: 11,
      question: 'How can I contact Youdao’s Investor Relations Department?',
      answerHtml: `<p>In China:
      Pei Du
      IR Director
      Youdao, Inc.
      +86-10-8255-8907
      IR@rd.netease.com</p><p>The Piacente Group, Inc.
      Emilie Wu
      +86-21-6039-8363
      youdao@thepiacentegroup.com</p><p>In the United States:
      The Piacente Group, Inc.
      Brandi Piacente
      +1-212-481-2050
      youdao@thepiacentegroup.com</p>`,
    },
  ]
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title="Inverstor FAQs" />
        <div className={styles.mainContent}>
          <div className={styles.bordered}></div>
          <Collapse
            accordion
            defaultActiveKey={['1']}
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }): ReactElement => (
              <DownOutlined rotate={isActive ? -180 : 0} />
            )}
          >
            {faqsData.map((item) => {
              return (
                <Panel
                  header={`${item.index}.${item.question}`}
                  key={item.index}
                >
                  <article
                    className={styles.article}
                    dangerouslySetInnerHTML={{ __html: item.answerHtml }}
                  ></article>
                </Panel>
              )
            })}
          </Collapse>
        </div>
      </span>
    </div>
  )
}

export default InverstorFAQs
