/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './YearInvestorEvents.module.scss'
import { fetchAppearYears } from 'src/pages/home/store/slices/appearYears'
import { fetchYearReport } from 'src/pages/home/store/slices/yearReport'
import { RootState } from 'src/pages/home/store/rootReducer'
import { Select } from 'antd'
import { InvestorEventItem } from 'src/pages/home/components/InvestorEventItem'
import moment from 'moment'

const { Option } = Select

interface InvestorEventsProps {
  showVideo: boolean
}

function YearInvestorEvents(props: InvestorEventsProps): ReactElement {
  const { showVideo } = props
  const [year, setYear] = useState('')

  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAppearYears())
  }, [])

  useEffect(() => {
    if (year) {
      const startStamp = moment()
        .year(Number(year))
        .tz('America/New_York')
        .startOf('year')
        .valueOf()
      const endStamp = moment()
        .year(Number(year))
        .tz('America/New_York')
        .endOf('year')
        .valueOf()
      dispatch(
        fetchYearReport({
          startStamp,
          endStamp,
        }),
      )
    }
  }, [year])

  // 绑定数据
  const { years, report } = useSelector((state: RootState) => {
    return {
      // years: [allYears].concat(state.appearYears.years),
      years: state.appearYears.years,
      report: state.yearReport.report,
    }
  }, shallowEqual)

  function handleChange(value: string): void {
    setYear(value)
  }

  if (years && years[0] && !year) {
    setYear(years[0])
  }

  return (
    <div className={styles.container}>
      <Select
        key={years && years[0] ? years[0] : ''}
        defaultValue={years && years[0] ? years[0] : ''}
        onChange={handleChange}
        loading={!years || !years.length ? true : false}
        dropdownClassName="dropdown"
      >
        {years.map((item) => {
          return (
            <Option value={item} key={item}>
              {item}
            </Option>
          )
        })}
      </Select>
      <div className={styles.content}>
        {report.length ? (
          report.map((item) => {
            const itemParsed = { ...item }
            return (
              <InvestorEventItem
                event={itemParsed}
                key={item.shortId}
                showVideo={showVideo}
              />
            )
          })
        ) : (
          <div className={styles.empty}>No data for selected year</div>
        )}
      </div>
    </div>
  )
}

YearInvestorEvents.defaultProps = {
  showVideo: true,
}

export default YearInvestorEvents
