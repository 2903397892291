/**
 *
 *
 * @author fuyg
 * @date  2020-06-09
 */
import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'

const theApi = `${API_PREFIX}/pub/news/details`

export interface NewsDetailRequestParams {
  id: string
}

export interface NewsDetailResponseData {
  content: string
  dateStamp: number
  title: string
}

async function getNewsDetail(
  params: NewsDetailRequestParams,
): Promise<NewsDetailResponseData> {
  const url = buildUrl(theApi, {
    id: params.id,
  })
  const response = await axios.get(url)
  return response.data.data as NewsDetailResponseData
}

export { getNewsDetail }
