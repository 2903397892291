import React, { ReactElement } from 'react'
import styles from './Contact.module.scss'

interface ContactProps {
  hasTitle: boolean
  showAllContact: boolean
}

function Contact(props: ContactProps): ReactElement {
  return (
    <div
      className={`${styles.container} ${
        props.hasTitle ? '' : styles.whiteBackground
      }`}
    >
      <div className={styles.center}>
        <h3>{props.hasTitle ? 'Contacts' : null}</h3>
        <div className={styles.content}>
          <div className={styles.contactItem}>
            <div className={styles.name}>Jeffrey Wang</div>
            <p>Investor Relations Director</p>
            <div className={styles.contact}>
              <div className={styles.item}>Youdao, Inc.</div>
              <span className={styles.line} />
              <div className={styles.item}>+86-10-82558163-89980</div>
              <span className={styles.line} />
              <div className={styles.item}>ir@rd.netease.com</div>
            </div>
          </div>
          {props.showAllContact ? (
            <div className={styles.contactItem}>
              <div className={styles.name}>Regina Wang</div>
              <p>Senior IR Manager</p>
              <div className={styles.contact}>
                <div className={styles.item}>Youdao, Inc.</div>
                <span className={styles.line} />
                <div className={styles.item}>+86-010-82558963</div>
                <span className={styles.line} />
                <div className={styles.item}>ir@rd.netease.com</div>
              </div>
            </div>
          ) : null}
          <div className={styles.contactItem}>
            <div className={styles.name}>Helen Wu</div>
            <p>Piacente Financial Communications</p>
            <div className={styles.contact}>
              <div className={styles.item}>The Piacente Group, Inc.</div>
              <span className={styles.line} />
              <div className={styles.item}>+86-10-6508-0677</div>
              <span className={styles.line} />
              <div className={styles.item}>youdao@thepiacentegroup.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
