/**
 *
 * @author fuyg
 * @date  2020-06-01
 */

import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'
import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/report/recent`

export interface RequestParams {
  count: number
}

export interface RawVideoItem {
  name: string
  url: string
  time: number
}

export type RawAnnexItem = RawVideoItem

export interface VideoItem extends RawVideoItem {
  shortId: string
}

export type AnnexItem = VideoItem

export interface RawInvestorEvent {
  annexList: RawAnnexItem[]
  content?: string
  timeStamp: number
  title: string
  id: string
  videoList?: RawVideoItem[]
  webCastName: string
  webCast: string
}

export interface InvestorEvent {
  annexList: AnnexItem[]
  content?: string
  time: number
  title: string
  id: string
  videoList: VideoItem[]
  webCastName: string
  webCast: string
  shortId: string
}

function parseInvestorEvent(item: RawInvestorEvent): InvestorEvent {
  const { annexList, videoList } = item
  const parsedAnnexList = annexList.map((annex) => {
    return {
      ...annex,
      shortId: shortid.generate(),
    }
  })

  const parsedVideoList = videoList
    ? videoList.map((video) => {
        return { ...video, shortId: shortid.generate() }
      })
    : []

  const parsed: InvestorEvent = {
    ...item,
    time: item.timeStamp,
    videoList: parsedVideoList,
    annexList: parsedAnnexList,
    shortId: shortid.generate(),
  }
  return parsed
}

function parseResponseData(data: RawInvestorEvent[]): InvestorEvent[] {
  if (!data || !data.map) {
    return []
  }
  return data.map(parseInvestorEvent)
}

async function getRecentInvestorEvents(
  params?: RequestParams,
): Promise<InvestorEvent[]> {
  const url = buildUrl(theApi, {
    count: (params && params.count ? params.count : 5).toString(),
  })
  const response = await axios.get(url)
  return parseResponseData(response.data.data as RawInvestorEvent[])
}

export { getRecentInvestorEvents, parseResponseData, parseInvestorEvent }
