/**
 *
 * @author fuyg
 * @date  2020-06-04
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './RecentNewsArticle.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { YearNews } from 'src/pages/home/components/YearNews'
import { navText } from 'src/utils/navText'

function RecentNewsArticle(): ReactElement {
  return (
    <div className={styles.container}>
      <BigTitle title={navText.recentNews} />
      <div className={styles.content}>
        <YearNews />
      </div>
    </div>
  )
}

export default RecentNewsArticle
