/**
 *
 * @author yangxin
 * @date  2020-06-12
 */

import React, { ReactElement } from 'react'
import styles from './FilingButton.module.scss'
import { docType } from 'src/utils/docType'
import { ReactComponent as HtmlIcon } from './html.svg'
import { ReactComponent as WordIcon } from './word.svg'
import { ReactComponent as PdfIcon } from './pdf.svg'
import { ReactComponent as ExcelIcon } from './excel.svg'
import { ReactComponent as XbrlIcon } from './xbrl.svg'
import { ReactComponent as HtmlHoverIcon } from './htmlHover.svg'
import { ReactComponent as WordHoverIcon } from './wordHover.svg'
import { ReactComponent as PdfHoverIcon } from './pdfHover.svg'
import { ReactComponent as ExcelHoverIcon } from './excelHover.svg'
import { ReactComponent as XbrlHoverIcon } from './xbrlHover.svg'

interface DownloadButtonProps {
  type: string
  url: string
  downloadName: string
}

function FilingButton(props: DownloadButtonProps): ReactElement {
  let Icon = null
  let HoverIcon = null
  let linkTarget = '_self'
  switch (props.type) {
    case docType.HTML:
      Icon = HtmlIcon
      HoverIcon = HtmlHoverIcon
      linkTarget = '_blank'
      break
    case docType.PDF:
      Icon = PdfIcon
      HoverIcon = PdfHoverIcon
      linkTarget = '_blank'
      break
    case docType.EXCEL:
      Icon = ExcelIcon
      HoverIcon = ExcelHoverIcon
      break
    case docType.XBRL:
      Icon = XbrlIcon
      HoverIcon = XbrlHoverIcon
      break
    default:
      Icon = WordIcon
      HoverIcon = WordHoverIcon
  }

  return (
    <a href={props.url} target={linkTarget} className={styles.container}>
      <Icon className={styles.icon} />
      <HoverIcon className={styles.hoverIcon} />
    </a>
  )
}

export default FilingButton
