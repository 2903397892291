/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './InvestorEventsArticle.module.scss'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from '../BigTitle'
import { YearInvestorEvents } from '../YearInvestorEvents'
import { navText } from 'src/utils/navText'

function InvestorEventsArticle(): ReactElement {
  return (
    <div className={styles.container}>
      <BigTitle title={navText.investorEvents} />
      <YearInvestorEvents />
    </div>
  )
}

export default InvestorEventsArticle
