/**
 *
 * @author yangxin
 * @date  2020-06-17
 */

import React, { ReactElement } from 'react'
import styles from './ContactView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import Contact from 'src/pages/home/components/Contact'
import { navText } from 'src/utils/navText'

function ContactView(): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BigTitle title={navText.irContacts} />
        <div className={styles.mainContent}>
          <Contact hasTitle={false} showAllContact={false} />
        </div>
      </div>
    </div>
  )
}

export default ContactView
