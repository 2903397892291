/**
 *
 * @author bjhuangdy
 * @date  2022-10-31
 */

import React, { ReactElement } from 'react'
import styles from './StockInfo.module.scss'
import DateSelect from './DateSelect'
import { StockItemData } from 'src/pages/home/apis/stock'

interface HistoryPriceProps {
  latestData: StockItemData
  yearArr: number[]
}

function HistoryPriceLookUp(props: HistoryPriceProps): ReactElement {
  return (
    <div className={styles['history-price-wrap']}>
      <h2>Historical Price Look Up</h2>
      <DateSelect {...props}></DateSelect>
    </div>
  )
}

export default HistoryPriceLookUp
