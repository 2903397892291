import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  OperatingDataItem,
  getOperatingData,
} from 'src/pages/home/apis/operatingData'

interface OperatingDataState {
  loading: boolean
  error: string
  data: OperatingDataItem[]
}

const initialState: OperatingDataState = {
  loading: false,
  error: '',
  data: [],
}

const operatingDataSlice = createSlice({
  name: 'operatingDataSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<OperatingDataItem[]>): void {
      state.loading = false
      state.data = action.payload
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = operatingDataSlice.actions

export default operatingDataSlice.reducer

export const fetchOperatingData = (): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getOperatingData()
    dispatch(getDataSuccess(data))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
