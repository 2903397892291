/**
 *
 *
 * @author fuyg
 * @date  2020-06-22
 */
import { axios, API_PREFIX } from 'src/http'

const theApi = `${API_PREFIX}/pub/config/otherFinancialConfig`

interface RawFinancialConfig {
  downloadName: string
  downloadUrl: string
  nowDate: number
  nowRMB: string
  nowUSD: string
  pastDate: number
  pastRMB: string
  exchangeRate: string
}

export interface FinancialConfig {
  downloadName: string
  downloadUrl: string
  nowDate: number
  nowRMB: number
  nowUSD: number
  pastDate: number
  pastRMB: number
  exchangeRate: number
}

function parseResponseData(data: RawFinancialConfig): FinancialConfig {
  const { nowRMB, nowUSD, pastRMB, exchangeRate } = data
  return {
    ...data,
    nowRMB: parseInt(nowRMB, 10),
    nowUSD: parseInt(nowUSD, 10),
    pastRMB: parseInt(pastRMB, 10),
    exchangeRate: parseFloat(exchangeRate),
  }
}

async function getFinancialConfig(): Promise<FinancialConfig> {
  const url = theApi
  const repsonse = await axios.get(url)
  const parsed = parseResponseData(repsonse.data.data as RawFinancialConfig)
  return parsed
}

export { getFinancialConfig }
