/**
 *
 * @author fuyg
 * @date  2020-05-26
 */

import React, { ReactElement, useState, useEffect } from 'react'
import styles from './RoundLightButton.module.scss'
import { Button } from 'antd'
import { isMobile } from 'src/utils/isMobile'
import classnames from 'classnames'

interface RoundLightButtonProps {
  text: string
  icon: React.ElementType
  onClick?: React.MouseEventHandler<HTMLElement>
  url?: string
  openInNewPage?: boolean
}

function RoundLightButton(props: RoundLightButtonProps): ReactElement {
  const Icon = props.icon
  const { url, text, openInNewPage, onClick } = props
  const [isCurrentMobile, setIsCurrentMobile] = useState(false)

  useEffect(() => {
    const isMobileTag = isMobile()
    setIsCurrentMobile(isMobileTag)
  }, [])

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (onClick) {
      onClick(e)
    }
  }

  const target = openInNewPage ? '_blank' : ''

  if (url) {
    return (
      <span
        className={classnames(
          styles.container,
          isCurrentMobile ? '' : styles.pcBtn,
        )}
      >
        <Button onClick={handleClick}>
          <Icon className={styles.icon} />
          <a href={url} target={target} className={styles.link}>
            {text}
          </a>
        </Button>
      </span>
    )
  } else {
    return (
      <span
        className={classnames(
          styles.container,
          isCurrentMobile ? '' : styles.pcBtn,
        )}
      >
        <Button onClick={handleClick}>
          <Icon className={styles.icon} />
          <span className={styles.text}>{text}</span>
        </Button>
      </span>
    )
  }
}

RoundLightButton.defaultProps = {
  openInNewPage: true,
}

export default RoundLightButton
