/**
 *
 * @author fuyg
 * @date  2020-05-20
 */

import React, { ReactElement } from 'react'
import classnames from 'classnames'
import styles from './Slogan.module.scss'

function Slogan(): ReactElement {
  return (
    <div className={classnames(styles.container, 'home-slogan')}>
      <div className={styles.content}>
        <p>Youdao</p>
        <p>Makes Learning Happen</p>
      </div>
    </div>
  )
}

export default Slogan
