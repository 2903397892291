/**
 *
 * @author fuyg
 * @date  2020-06-04
 */

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import moment from 'moment'
import styles from './StockInfo.module.scss'
import { fetchStock } from 'src/pages/home/store/slices/stock'
import { RootState } from 'src/pages/home/store/rootReducer'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { StockState } from 'src/pages/home/components/StockState'
import { formatStock } from 'src/pages/home/utils/stock'
import StockChart from './StockChart'
import HistoryPriceLookUp from './HistoryPriceLookUp'
import { StockItemData } from 'src/pages/home/apis/stock'

function StockInfo(): ReactElement {
  const curDate = new Date()
  const curY = curDate.getFullYear() // 当前年份
  const curM = curDate.getMonth() + 1 // 当前月份
  const curDay = curDate.getDate()
  const defaultData = {
    openPrice: '',
    closePrice: '',
    minimumPrice: '',
    maximumPrice: '',
    priceChange: '',
    changePercent: '',
    volume: '',
    tradeDate: {
      year: curY,
      month: curM,
      day: curDay,
    },
  }
  const [yearArr, setYearArr] = useState<number[]>([])
  const [latestData, setLatestData] = useState<StockItemData>(defaultData)

  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStock())
    // 默认请求4个月的历史股价数据
    const from = moment().subtract(4, 'months').format('YYYY-MM-DD')
    const to = moment().format('YYYY-MM-DD')
    const params = { from, to }
    dispatch(fetchStock(params))
  }, [])

  // 绑定数据
  const { stock, stockList } = useSelector((state: RootState) => {
    return {
      stock: state.stock.stock,
      stockList: state.stock.stockList,
    }
  }, shallowEqual)

  const theStock = useMemo(() => {
    return formatStock(stock)
  }, [stock])

  // 获取所有可查询的年份，默认年份从22年开始算起（因为只有22年以后有数据）
  const getAllYear = (): number[] => {
    const startY = 2022
    const diff = curY - startY
    let yearList: number[] = []

    if (diff === 0) {
      yearList = [startY]
    } else {
      for (let index = 0; index <= diff; index++) {
        const year = startY + index
        yearList.push(year)
      }
    }

    return yearList
  }

  useEffect(() => {
    if (!stockList.length) return
    // 获取年月日
    const latestStock = stockList[stockList.length - 1]
    setLatestData(latestStock)
    const yearList = getAllYear()
    setYearArr(yearList)
  }, [stockList])

  return (
    <div className={styles.container}>
      <BigTitle title="Stock Quote & Chart" />
      <div className={styles.content}>
        <div className={styles.stock}>
          <StockState
            stock={stock}
            useBackgrondImage={true}
            changeStateStyle={true}
          />
        </div>
        <div className={styles.table}>
          <table>
            <tbody>
              <tr>
                <td className={styles.right + ' ' + styles.bottom}>
                  <div>
                    <h5>${theStock.open}</h5>
                    <p>Open</p>
                  </div>
                </td>
                <td className={styles.bottom}>
                  <div>
                    <h5>
                      {theStock.change}({theStock.changePercent})
                    </h5>
                    <p>Change</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.right + ' ' + styles.bottom}>
                  <div>
                    <h5>{theStock.range}</h5>
                    <p>{`Day's Range`}</p>
                  </div>
                </td>
                <td className={styles.bottom}>
                  <div>
                    <h5>{theStock.yearRange}</h5>
                    <p>52-Week Range</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.right}>
                  <div>
                    <h5>{theStock.volume}</h5>
                    <p>Volume</p>
                  </div>
                </td>
                <td>
                  <div>
                    <h5>{theStock.marketCap}</h5>
                    <p>Market Cap</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <StockChart list={stockList}></StockChart>
      <HistoryPriceLookUp
        yearArr={yearArr}
        latestData={latestData}
      ></HistoryPriceLookUp>
    </div>
  )
}

export default StockInfo
