/**
 *
 *
 * @author fuyg
 * @date  2020-06-03
 */
import { axios, API_PREFIX } from 'src/http'

const theApi = `${API_PREFIX}/pub/report/appearYears`

async function getAppearYears(): Promise<number[]> {
  const url = theApi
  const response = await axios.get(url)
  return response.data.data
}

export { getAppearYears }
