/**
 *
 * @author fuyg
 * @date  2020-05-26
 */

import React, { ReactElement } from 'react'
import { RoundLightButton } from 'src/components/RoundLightButton'
// import styles from './DownloadButton.module.scss'
import { ReactComponent as Icon } from './financial.svg'

interface DownloadButtonProps {
  text: string
  url: string
  downloadName: string
  openInNewPage?: boolean
}

function DownloadButton(props: DownloadButtonProps): ReactElement {
  const { downloadName, openInNewPage, text, url } = props

  const handleClick = (): void => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = url
    if (openInNewPage) {
      a.target = '_blank'
    }

    a.setAttribute('download', downloadName)
    a.click()

    document.body.removeChild(a)
  }

  return <RoundLightButton icon={Icon} text={text} onClick={handleClick} />
}

DownloadButton.defaultProps = {
  openInNewPage: true,
}

export default DownloadButton
