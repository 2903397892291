/**
 *
 *
 * @author wangjm
 * @date  2020-06-09
 */
import { axios, API_PREFIX } from 'src/http'

const theApi = `${API_PREFIX}/pub/subscribe/email` // 首次订阅
const subscribedTypesApi = `${API_PREFIX}/pub/subscribe/status/get` // 获取已订阅类型
const updateApi = `${API_PREFIX}/pub/subscribe/status/update` // 更新订阅

export interface CheckBoxParams {
  stock?: string | boolean
  financial?: string | boolean
  news?: string | boolean
  sec?: string | boolean
}

export interface SubscribeParams extends CheckBoxParams {
  name: string
  email: string
  company?: string
  comment?: string
}

export interface SubscribeReponse {
  code: number
  message: string
}

async function subscribe(params: SubscribeParams): Promise<SubscribeReponse> {
  const url = theApi
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsParsed = new URLSearchParams(params as any)
  const response = await axios.post(url, paramsParsed)
  return response.data as SubscribeReponse
}

export interface ConfirmParams extends CheckBoxParams {
  cid: string
  subscription?: string | boolean
}

async function confirm(params: ConfirmParams): Promise<SubscribeReponse> {
  const url = updateApi
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsParsed = new URLSearchParams(params as any)
  const response = await axios.post(url, paramsParsed)
  return response.data as SubscribeReponse
}

interface GetSubscribedTypesParams {
  cid: string
}

interface GetSubscribedTypesRes extends SubscribeReponse {
  data: CheckBoxParams
}

// 获取已订阅类型
async function getSubscribedTypes(
  params: GetSubscribedTypesParams,
): Promise<GetSubscribedTypesRes> {
  const response = await axios.get(subscribedTypesApi, { params: params })
  return response.data as GetSubscribedTypesRes
}

export { subscribe, confirm, getSubscribedTypes }
