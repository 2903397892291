/**
 *
 *
 * @author fuyg
 * @date  2020-06-08
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  ReportDetailRequestParams,
  getReportDetail,
} from 'src/pages/home/apis/reportDetail'
import { InvestorEvent } from 'src/pages/home/apis/recentInvestorEvents'

interface ReportDetailState {
  loading: boolean
  error: string
  detail: InvestorEvent | null
}

const initialState: ReportDetailState = {
  loading: false,
  error: '',
  detail: null,
}

const reportDetailSlice = createSlice({
  name: 'reportDetail',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<InvestorEvent>): void {
      state.loading = false
      state.detail = action.payload
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getDataStart,
  getDataSuccess,
  getDataError,
} = reportDetailSlice.actions

export default reportDetailSlice.reducer

export const fetchDetailData = (
  params: ReportDetailRequestParams,
): AppThunk => async (dispatch): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getReportDetail(params)
    dispatch(getDataSuccess(data))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
