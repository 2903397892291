/**
 *
 * @author fuyg
 * @date  2020-05-29
 */

import axios from './axios'

const API_PREFIX = '/api/v1'

export { axios, API_PREFIX }
