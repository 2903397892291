import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  NewsRequestParams,
  NewsSummary,
  NewsActionParams,
  getNews,
} from 'src/pages/home/apis/news'

interface NewsState {
  loading: boolean
  error: string
  news: NewsSummary[]
  otherNews: NewsSummary[]
}

const initialState: NewsState = {
  loading: false,
  error: '',
  news: [],
  otherNews: [],
}

const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {
    getDataStart(state): void {
      state.loading = true
    },
    getDataSuccess(state, action: PayloadAction<NewsActionParams>): void {
      state.loading = false
      if (action.payload.type === 'financial') {
        state.news = action.payload.data
      } else {
        state.otherNews = action.payload.data
      }
    },
    getDataError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { getDataStart, getDataSuccess, getDataError } = newsSlice.actions

export default newsSlice.reducer

export const fetchNews = (params: NewsRequestParams): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getDataStart())
    const data = await getNews(params)
    const type = params && params.type
    const obj = {
      type,
      data,
    }
    dispatch(getDataSuccess(obj))
  } catch (err) {
    dispatch(getDataError(err as string))
  }
}
