/**
 *
 * @author fuyg
 * @date  2020-06-01
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  RecentNewsRequestParams,
  getRecentNews,
} from 'src/pages/home/apis/recentNews'
import { NewsSummary, NewsActionParams } from 'src/pages/home/apis/news'
import { AppThunk } from 'src/pages/home/store'

interface RecentNewsState {
  loading: boolean
  error: string
  recentNews: NewsSummary[]
  otherRecentNews: NewsSummary[]
}

const initialState: RecentNewsState = {
  loading: false,
  error: '',
  recentNews: [],
  otherRecentNews: [],
}

const slice = createSlice({
  name: 'recentNews',
  initialState,
  reducers: {
    getRecentNewsStart(state): void {
      state.loading = true
    },
    getRecentNewsSuccess(state, action: PayloadAction<NewsActionParams>): void {
      state.loading = false
      if (action.payload.type === 'financial') {
        state.recentNews = action.payload.data
      } else {
        state.otherRecentNews = action.payload.data
      }
    },

    getRecentNewsError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getRecentNewsStart,
  getRecentNewsSuccess,
  getRecentNewsError,
} = slice.actions

export default slice.reducer

export const fetchRecentNews = (params?: RecentNewsRequestParams): AppThunk => {
  return async (dispatch): Promise<void> => {
    try {
      const type = (params && params.type) || 'financial'
      dispatch(getRecentNewsStart())
      const data = await getRecentNews(params)
      const obj = {
        type,
        data,
      }
      dispatch(getRecentNewsSuccess(obj))
    } catch (err) {
      dispatch(getRecentNewsError(err as string))
    }
  }
}
