/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
// import styles from './InvestorEventsView.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { InvestorEventsArticle } from '../../components/InvestorEventsArticle'
// import { RouteConfigComponentProps } from 'react-router-config'

function InvestorEventsView(/*props: RouteConfigComponentProps*/): ReactElement {
  return <InvestorEventsArticle />
}

export default InvestorEventsView
