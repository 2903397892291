/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import { axios, API_PREFIX } from 'src/http'
import shortid from 'shortid'

import { RawAnnexItem, AnnexItem } from './recentInvestorEvents'

const theApi = `${API_PREFIX}/pub/annualReport`

export interface RawAnnualReport {
  annexList: RawAnnexItem[]
  dateStamp: number
  title: string
}

export interface AnnualReport {
  annexList: AnnexItem[]
  dateStamp: number
  title: string
  shortId: string
}

function parseAnnualReport(item: RawAnnualReport): AnnualReport {
  const { annexList } = item
  const parsedAnnexList = annexList.map((annex) => {
    return {
      ...annex,
      shortId: shortid.generate(),
    }
  })

  const parsed: AnnualReport = {
    ...item,
    annexList: parsedAnnexList,
    shortId: shortid.generate(),
  }
  return parsed
}

function parseResponseData(data: RawAnnualReport[]): AnnualReport[] {
  if (!data || !data.map) {
    return []
  }
  return data.map(parseAnnualReport)
}

async function getAnnualReports(): Promise<AnnualReport[]> {
  const url = theApi
  const response = await axios.get(url)
  return parseResponseData(response.data.data as RawAnnualReport[])
}

export { getAnnualReports, parseResponseData, parseAnnualReport }
