import React, { ReactElement } from 'react'
import styles from './Overview.module.scss'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { Helmet } from 'react-helmet'
import { createPageTitle } from 'src/pages/home/utils/pageTitle'
// import routePath from 'src/pages/home/router/routePath'
// import shortid from 'shortid'
// import { navText } from 'src/utils/navText'

// import { SideNavigation, NavItem } from 'src/components/SideNavigation'

function Overview(props: RouteConfigComponentProps): ReactElement {
  // const navList: NavItem[] = [
  // {
  // id: shortid.generate(),
  // name: navText.businessOverview,
  // routeLink: routePath.OVERVIEW_BUSINESS,
  // },
  // {
  // id: shortid.generate(),
  // name: 'Long-term-view',
  // routeLink: routePath.OVERVIEW_LONGTERM,
  // },
  // {
  // id: shortid.generate(),
  // name: 'Investor FAQs',
  // routeLink: routePath.OVERVIEW_INVESTOR,
  // },
  // ]
  const title = createPageTitle()
  return (
    <div className={styles.container}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default Overview
