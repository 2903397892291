/**
 *
 * @author yangxin
 * @date  2020-06-05
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './Governance.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'
import routePath from 'src/pages/home/router/routePath'
import shortid from 'shortid'

import { SideNavigation } from 'src/components/SideNavigation'

function Governance(props: RouteConfigComponentProps): ReactElement {
  const navList = [
    {
      id: shortid.generate(),
      name: 'Corporate Governance',
      routeLink: routePath.GOVERNANCE_CORPORATE,
    },
    {
      id: shortid.generate(),
      name: 'Board Committees',
      routeLink: routePath.GOVERNANCE_COMMITTEES,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <SideNavigation navList={navList} />
      </div>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default Governance
