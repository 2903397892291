/**
 *
 * @author yangxin
 * @date  2020-06-10
 */
import React, { ReactElement } from 'react'
import styles from './LongTermView.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'

function createMarkup(): { __html: string } {
  const html =
    'Biggest Challenge Facing 2020 Online Education -- \nnot “Expensive Traffic” but “Homogenization'
  return {
    __html: html,
  }
}
function LongTermView(): ReactElement {
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.longTermView} />
        <div className={styles.mainContent}>
          <article
            className={styles.article}
            dangerouslySetInnerHTML={createMarkup()}
          ></article>
        </div>
      </span>
    </div>
  )
}

export default LongTermView
