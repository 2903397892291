/**
 *
 * @author fuyg
 * @date  2020-06-01
 */

function buildUrl(
  url: string,
  search?: null | { [key: string]: string },
): string {
  if (!search) {
    return url
  }
  const params = new URLSearchParams(search)
  const str = params.toString()
  return `${url}?${str}`
}

function parseUrl(
  urlWithSearch: string,
): {
  url: string
  search: URLSearchParams
} {
  const arr = urlWithSearch.split('?')
  const url = arr[0]
  const search = arr[1]
  const params = new URLSearchParams(search)
  return {
    url,
    search: params,
  }
}

export { buildUrl, parseUrl }
