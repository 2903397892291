/**
 *
 * @author yangxin
 * @date  2020-06-10
 */

import React, { ReactElement } from 'react'
import styles from './AnnualReportItem.module.scss'
import { DownloadButton } from 'src/pages/home/components/DownloadButton'
import { AnnualReport } from 'src/pages/home/apis/annualReport'
import { shouldOpenNewPage } from 'src/utils/docType'

interface AnnualReportItemProps {
  report: AnnualReport
}

function AnnualReportItem(props: AnnualReportItemProps): ReactElement {
  const { report } = props
  const { annexList } = report
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{report.title}</h3>

      <div>
        {annexList.map((annexItem) => {
          return (
            <DownloadButton
              key={annexItem.shortId}
              url={annexItem.url}
              downloadName={annexItem.url}
              text={annexItem.name}
              openInNewPage={shouldOpenNewPage(annexItem.url)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default AnnualReportItem
