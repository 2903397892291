/**
 *
 * @author fuyg
 * @date  2020-06-10
 */

const navText = {
  overview: 'Overview',
  businessOverview: 'Business Overview',
  longTermView: 'Long-term-view',
  qa: 'Top Investor Questions (Q&A)',
  companyInfo: 'Company Info',
  companyProfile: 'Company Profile',
  management: 'Management',
  boardOfDirectors: 'Board of Directors',
  financials: 'Financials',
  quanterlyEarnings: 'Quarterly Earnings',
  operating: 'Operating & Financial Statements',
  annualReports: 'Annual Reports',
  secFilings: 'SEC Filings',
  newEvents: 'News Events',
  investorEvents: 'Investor Events',
  eventCalendar: 'Event Calendar',
  recentNews: 'Recent News',
  stockInfo: 'Stock Info',
  stockQuoteChart: 'Stock Quote & Chart',
  analystConverage: 'Analyst Coverage',
  governance: 'Governance',
  corporateGovernance: 'Corporate Governance',
  boardCommittees: 'Board Committees',
  resources: 'Resources',
  businessIntroVideos: 'Videos',
  irContacts: 'IR Contacts',
  emailAlerts: 'E-mail Alerts',
}

export { navText }
