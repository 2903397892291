/**
 *
 * @author bjhuangdy
 * @date  2022-10-31
 */

import React, { ReactElement, useState, useEffect } from 'react'
import { Select, message } from 'antd'
import styles from './DateSelect.module.scss'
import {
  StockItemData,
  StockListResponseData,
  getStockList,
} from 'src/pages/home/apis/stock'

const { Option } = Select
const monthList = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
]

const stockLable = [
  'Volume',
  'Open',
  'Closing Price',
  "Day's High",
  "Day's Low",
]

const stockKey = [
  'volume',
  'openPrice',
  'closePrice',
  'maximumPrice',
  'minimumPrice',
]

interface DateSelectProps {
  latestData: StockItemData
  yearArr: number[]
}

function DateSelect({
  latestData,
  yearArr = [],
}: DateSelectProps): ReactElement {
  const tradeDate = latestData.tradeDate
  const curMLabel =
    monthList?.find((_) => _.value === tradeDate.month)?.label || ''
  const [selectYear, setSelectYear] = useState(tradeDate.year)
  const [selectMonth, setSelectMonth] = useState(curMLabel)
  const [selectDay, setSelectDay] = useState(tradeDate.day)
  const [dayList, setDayList] = useState<number[]>([])
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stockData, setStockData] = useState<any>(latestData)
  const [isCanSearch, setIsCanSearch] = useState(false) // 是否可以搜索查询
  const [isInReq, setIsInReq] = useState(false) // 是否在请求中

  const getMonth = (): number => {
    return monthList?.find((_) => _.label === selectMonth)?.value || 1
  }

  const yearChange = (val: number): void => {
    setSelectYear(val)
    setIsCanSearch(true)
  }

  // 处理当前月份对应的天数
  const handleDateList = (): void => {
    const curM = getMonth()
    const date = new Date(selectYear, curM, 0)
    const dayNum = date.getDate()
    const dayArr: number[] = []
    for (let k = 0; k < dayNum; k++) {
      dayArr.push(k + 1)
    }

    setDayList([...dayArr])
  }

  useEffect(() => {
    if (!selectMonth || !selectYear) return
    handleDateList()
  }, [selectMonth])

  useEffect(() => {
    // 首次渲染获取历史最新股价数据显示
    setStockData(latestData)
    // 避免当日股价可能没有，因此默认展示历史最新的日期
    setSelectDay(latestData.tradeDate.day)
  }, [latestData])

  const monthChange = (val: string): void => {
    setSelectMonth(val)
    setSelectDay(1)
    setIsCanSearch(true)
  }

  const dayChange = (val: number): void => {
    setSelectDay(val)
    setIsCanSearch(true)
  }

  // 查询某天的股价信息
  const getHistoryStock = async (params: {
    from: string
    to: string
  }): Promise<void> => {
    try {
      const res: StockListResponseData = await getStockList(params)
      if (res.code === 0) {
        if (res.data.length > 0) {
          setStockData(res.data[0])
        } else {
          setStockData(null)
        }
        setIsCanSearch(false)
      } else {
        message.error(res.message || '股价查询失败')
      }
      setIsInReq(false)
    } catch (error) {
      setIsInReq(false)
      message.error(error?.message || '股价查询失败')
    }
  }

  const handleSearch = (): void | boolean => {
    // 年月日没改变 或 请求中 都不允许再次请求
    if (!isCanSearch || isInReq) return false
    setIsInReq(true)
    const month = getMonth()
    // 由于移动端中使用moment来format日期格式会出现`Invalid date`的问题, 所以月份和日期不满两位手动补0，
    const m = month >= 10 ? month : '0' + month
    const d = selectDay >= 10 ? selectDay : '0' + selectDay
    const date = `${selectYear}-${m}-${d}`
    const params = { from: date, to: date }
    getHistoryStock(params)
  }

  return (
    <div className={styles['date-select-wrap']}>
      <div className={styles['select-box']}>
        {/* 月份 */}
        <div className={styles['item']}>
          <Select
            style={{ width: 100 }}
            defaultValue={curMLabel}
            onChange={monthChange}
          >
            {monthList?.map((item) => (
              <Option key={item.value} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        {/* 日期 */}
        <div className={styles['item']}>
          <Select style={{ width: 100 }} value={selectDay} onChange={dayChange}>
            {dayList?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
        {/* 年份 */}
        <div className={styles['item']}>
          <Select
            style={{ width: 100 }}
            defaultValue={tradeDate.year}
            onChange={yearChange}
          >
            {yearArr?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
        {/* 查询按钮 */}
        <div className={styles['item']}>
          <div className={styles['search-button']} onClick={handleSearch}>
            Look Up
          </div>
        </div>
      </div>
      <div className={styles['stock-info']}>
        {stockData ? (
          <>
            <div className={styles['stock-date']}>
              <span>
                {selectMonth} {selectDay}，{selectYear}
              </span>
            </div>
            <div className={styles['stock-table']}>
              <div className={styles['stock-label-box']}>
                {stockLable.map((item) => (
                  <span key={item} className={styles['label']}>
                    {item}
                  </span>
                ))}
              </div>
              <div className={styles['stock-value-box']}>
                {stockKey.map((key) => (
                  <span key={key} className={styles['value']}>
                    {key === 'volume' ? '' : '$'}
                    {stockData[key]}
                    {key === 'volume' ? 'K' : ''}
                  </span>
                ))}
              </div>
            </div>
          </>
        ) : (
          'There is no data for the selected date.'
        )}
      </div>
    </div>
  )
}

export default DateSelect
