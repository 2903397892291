/**
 *
 * @author fuyg
 * @date  2020-06-08
 */

import routePath, { RoutePathName } from 'src/pages/home/router/routePath'

function replaceParams(
  path: string,
  params: { [key: string]: string },
): string {
  const names = Object.keys(params)
  let replaced = path
  names.forEach((item) => {
    const reg = new RegExp(':' + item, 'g')
    const value = params[item]
    replaced = replaced.replace(reg, value)
  })
  return replaced
}

function buildRoutePath(
  name: RoutePathName,
  params: { [key: string]: string },
): string {
  const path = routePath[name]
  return replaceParams(path, params)
}

export { buildRoutePath }
