/**
 *
 * @author fuyg
 * @date  2020-05-22
 */

import React, { ReactElement } from 'react'
import { ReactComponent as WebcastIcon } from './webcast.svg'
import { RoundLightButton } from 'src/components/RoundLightButton'

interface WebcastButtonProps {
  url: string
  text: string
}

function WebcastButton(props: WebcastButtonProps): ReactElement {
  const onClick = (): void => {
    window.open(props.url)
  }
  const text = props.text || 'Webcast'
  return <RoundLightButton icon={WebcastIcon} text={text} onClick={onClick} />
}

export default WebcastButton
