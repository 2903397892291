import React, { ReactElement } from 'react'
import styles from './OverviewIntro.module.scss'
import Contact from '../../components/Contact'
import { Banner } from 'src/pages/home/components/Banner'
import { EventsAndNews } from 'src/pages/home/components/EventsAndNews'

function OverviewIntro(): ReactElement {
  return (
    <div className={styles.container}>
      <Banner />
      <EventsAndNews propName="todo" />
      <Contact hasTitle={true} showAllContact={false} />
    </div>
  )
}

export default OverviewIntro
