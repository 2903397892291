/**
 *
 * @author fuyg
 * @date  2020-05-28
 */

import { axios, API_PREFIX } from 'src/http'

const theApi = `${API_PREFIX}/pub/stock`
const stockListApi = `${API_PREFIX}/pub/stock/daily`

export interface StockResponseData {
  change: number
  changePercent: number
  current: number
  marketCap: number
  open: number
  rangeLeft: number
  rangeRight: number
  volume: number
  yearRangeLeft: number
  yearRangeRight: number
}

export interface StockItemData {
  openPrice: string
  closePrice: string
  minimumPrice: string
  maximumPrice: string
  priceChange: string
  changePercent: string
  volume: string
  tradeDate: {
    year: number
    month: number
    day: number
  }
}

export interface StockListResponseData {
  data: StockItemData[]
  code: number
  message: string
}

export interface RequestParams {
  from: string
  to: string
}

async function getStock(): Promise<StockResponseData> {
  const response = await axios.get(theApi)
  return response.data.data as StockResponseData
}

async function getStockList(
  params: RequestParams,
): Promise<StockListResponseData> {
  const response = await axios.get(stockListApi, { params })
  return response.data as StockListResponseData
}

export { getStock, getStockList }
