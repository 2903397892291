/**
 * @author bjhuangdy
 * @date  2021-02-07
 */

import React, { ReactElement } from 'react'
import styles from './OtherReleases.module.scss'
import { NewsSummary } from '../../apis/news'
import { RecentNewsItem } from '../RecentNewsItem'

export interface OtherReleases {
  OTnews: NewsSummary[]
}
function OtherReleases(props: OtherReleases): ReactElement {
  const { OTnews } = props
  return (
    <div className={styles.container}>
      <p className={styles.title}>Other Releases</p>
      <div
        className={`${styles.content} ${
          OTnews.length > 0 ? null : styles.empty
        }`}
      >
        {OTnews.length > 0
          ? OTnews.map((item) => {
              return (
                <RecentNewsItem
                  key={item.shortId}
                  news={item}
                  styleFixed={true}
                />
              )
            })
          : 'Not available now'}
      </div>
    </div>
  )
}

export default OtherReleases
