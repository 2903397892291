/**
 *
 * @author yangxin
 * @date  2020-05-22
 */

import React, { ReactElement } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Location } from 'history'
import routePath from 'src/pages/home/router/routePath'
import { scrollToTop } from 'src/utils/scroll'
import styles from './TopNavigation.module.scss'
// import logo from './logo.png'
import { ReactComponent as LogoIcon } from './logo.svg'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { navText } from 'src/utils/navText'
import { debounce } from 'lodash'
import classnames from 'classnames'

interface NavigationItem {
  level: number
  name: string
  routeLink: string
  index: number
}

interface NavigationObject extends NavigationItem {
  children: NavigationItem[]
}
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TopNavigationProps extends RouteComponentProps {
  showNavigation: boolean
}

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TopNavigationState {
  navigationList: NavigationObject[]
  activeIndex: number
  // for mobile
  activeChildIndex: number
  showChild: boolean
  showNavigation: boolean
  pcShowChild: boolean
  clickedIndex: number
}

class TopNavigation extends React.PureComponent<
  TopNavigationProps,
  TopNavigationState
> {
  /***************************************************************************
   *  static properties and methods
   **************************************************************************/

  static defaultProps = {
    // TODO
  }

  constructor(props: TopNavigationProps) {
    super(props)

    this.state = {
      navigationList: [
        {
          level: 0,
          name: navText.overview,
          routeLink: routePath.OVERVIEW_INTRO,
          index: 0,
          children: [
            {
              level: 1,
              name: navText.companyProfile,
              routeLink: routePath.COMPANY_PROFILE,
              index: 0,
            },
            {
              level: 1,
              name: navText.management,
              routeLink: routePath.COMPANY_MANAGEMENT,
              index: 1,
            },
            {
              level: 1,
              name: navText.boardOfDirectors,
              routeLink: routePath.COMPANY_DIRECTORS,
              index: 2,
            },
          ],
        },
        {
          level: 0,
          index: 1,
          name: navText.financials,
          routeLink: routePath.FINANCIAL,
          children: [
            {
              level: 1,
              name: navText.quanterlyEarnings,
              routeLink: routePath.FINANCIALS_QUANTERLY_EARNINGS,
              index: 0,
            },
            // 暂时注释，不要删除
            // {
            // level: 1,
            // name: navText.operating,
            // routeLink: routePath.FINANCIALS_DATA,
            // index: 1,
            // },
            {
              level: 1,
              name: navText.annualReports,
              routeLink: routePath.FINANCIALS_ANNUAL_REPORT,
              index: 2,
            },
            {
              level: 1,
              name: navText.secFilings,
              routeLink: routePath.FINANCIALS_SEC,
              index: 3,
            },
          ],
        },
        {
          level: 0,
          name: navText.businessIntroVideos,
          routeLink: routePath.REPORT_INTRO_VIDEOS,
          index: 2,
          children: [],
        },
        {
          level: 0,
          name: navText.newEvents,
          routeLink: routePath.EVENT,
          index: 3,
          children: [
            {
              level: 1,
              name: navText.investorEvents,
              routeLink: routePath.EVENT_INVESTOR,
              index: 0,
            },
            {
              level: 1,
              name: navText.recentNews,
              routeLink: routePath.EVENT_NEWS,
              index: 1,
            },
          ],
        },
        {
          level: 0,
          name: navText.stockInfo,
          routeLink: routePath.STOCK_INFO,
          index: 4,
          children: [
            {
              level: 1,
              name: navText.stockQuoteChart,
              routeLink: routePath.STOCK_INFO_QUOTE_CHART,
              index: 0,
            },
            {
              level: 1,
              name: navText.analystConverage,
              routeLink: routePath.STOCK_INFO_ANALYST_COVERAGE,
              index: 1,
            },
          ],
        },
        {
          level: 0,
          name: navText.governance,
          routeLink: routePath.GOVERNANCE,
          index: 5,
          children: [
            {
              level: 1,
              name: navText.corporateGovernance,
              routeLink: routePath.GOVERNANCE_CORPORATE,
              index: 0,
            },
            {
              level: 1,
              name: navText.boardCommittees,
              routeLink: routePath.GOVERNANCE_COMMITTEES,
              index: 1,
            },
          ],
        },
        {
          level: 0,
          name: navText.resources,
          routeLink: routePath.RESOURCE,
          index: 6,
          children: [
            {
              level: 1,
              name: navText.irContacts,
              routeLink: routePath.RESOURCE_CONTACT,
              index: 0,
            },
            {
              level: 1,
              name: navText.emailAlerts,
              routeLink: routePath.RESOURCE_EMAIL,
              index: 1,
            },
          ],
        },
      ],
      activeIndex: -1,
      activeChildIndex: -1,
      showChild: false,
      showNavigation: false,
      pcShowChild: true,
      clickedIndex: -1,
    }

    // bind this
    this.nameMethod = this.nameMethod.bind(this)
    this.logoClick = this.logoClick.bind(this)
    this.navigationItemClick = this.navigationItemClick.bind(this)
    this.navigationToggle = this.navigationToggle.bind(this)
    this.childItemClick = this.childItemClick.bind(this)
    this.handleMouseMove = debounce(this.handleMouseMove.bind(this), 100)
  }

  render(): ReactElement {
    const navigationList = this.state.navigationList.map((navigationItem) => {
      const childrenNavigationList = navigationItem.children.map(
        (childItem) => {
          return (
            <span
              key={childItem.name}
              className={styles.childItem}
              onClick={(): void =>
                this.childItemClick(navigationItem, childItem)
              }
            >
              <span
                title={childItem.name}
                className={`${
                  navigationItem.index === this.state.activeIndex &&
                  childItem.index === this.state.activeChildIndex
                    ? styles.active
                    : ''
                }`}
              >
                {childItem.name}
              </span>
            </span>
          )
        },
      )
      return (
        <div key={navigationItem.name} className={styles.item}>
          <div
            onMouseMove={this.handleMouseMove}
            onClick={(e): void => this.navigationItemClick(navigationItem, e)}
            className={classnames(
              navigationItem.index === this.state.activeIndex
                ? styles.active
                : '',
              navigationItem.index === this.state.clickedIndex
                ? styles.active
                : '',
            )}
          >
            {navigationItem.name}
            {navigationItem.children.length > 0 ? (
              <ArrowIcon
                className={classnames(
                  styles.icon,
                  navigationItem.index === this.state.clickedIndex &&
                    this.state.showChild
                    ? styles.up
                    : '',
                )}
              />
            ) : null}
          </div>
          <div
            className={classnames(
              styles.subItem,
              this.state.pcShowChild ? styles.pcShowChild : '',
              navigationItem.index === this.state.clickedIndex &&
                this.state.showChild &&
                childrenNavigationList.length > 0
                ? styles.show
                : '',
            )}
          >
            {childrenNavigationList.length > 0 ? (
              <div className={styles.arrow} />
            ) : null}

            {childrenNavigationList}
          </div>
        </div>
      )
    })
    return (
      <div className={styles.container}>
        <div className={this.state.showNavigation ? styles.mask : ''} />
        {this.props.showNavigation ? (
          <div className={styles.content}>
            <div className={styles.logoContainer}>
              <LogoIcon className={styles.logo} onClick={this.logoClick} />
              <button className={styles.button} onClick={this.navigationToggle}>
                {this.state.showNavigation ? (
                  <CloseIcon className={styles.navIcon} />
                ) : (
                  <MenuIcon className={styles.navIcon} />
                )}
              </button>
            </div>

            <div className={styles.pcItemContainer}>{navigationList}</div>
            <div className={styles.itemContainer}>
              {this.state.showNavigation ? navigationList : null}
            </div>
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.logoContainer}>
              <LogoIcon className={styles.logo} onClick={this.logoClick} />
            </div>
          </div>
        )}
      </div>
    )
  }

  /***************************************************************************
   *  custom methods
   **************************************************************************/

  nameMethod(): void {
    // TODO
  }
  goPage(path: string): void {
    scrollToTop()
    this.props.history.push(path)
  }
  goOverviewIntro(): void {
    this.setState({ activeIndex: 0, activeChildIndex: -1 })
    this.goPage(routePath.OVERVIEW_INTRO)
  }

  logoClick(): void {
    this.goOverviewIntro()
  }

  navigationToggle(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
    e.nativeEvent.stopImmediatePropagation()
    if (!this.state.showNavigation) {
      this.changeActiveIndex(this.props.location)
    }
    this.setState({ showNavigation: !this.state.showNavigation })
  }

  navigationItemClick(
    navigationItem: NavigationObject,
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void {
    if (navigationItem.children.length === 0) {
      this.goPage(navigationItem.routeLink)
      return
    }
    const mediaQueryList = window.matchMedia('(min-width:1200px)')
    if (
      navigationItem.routeLink === routePath.OVERVIEW_INTRO &&
      mediaQueryList.matches
    ) {
      this.goOverviewIntro()
      return
    }
    e.nativeEvent.stopImmediatePropagation()
    if (mediaQueryList.matches) {
      const firstChild = navigationItem.children[0]
      this.goPage(firstChild.routeLink)
      return
    }
    if (this.state.clickedIndex === navigationItem.index) {
      this.setState({ showChild: !this.state.showChild })
    } else {
      this.setState({
        clickedIndex: navigationItem.index,
        showChild: true,
      })
    }
  }

  handleMouseMove(/*e: React.MouseEvent<HTMLElement, MouseEvent>*/): void {
    this.setState({
      pcShowChild: true,
    })
  }

  childItemClick(
    navigationItem: NavigationObject,
    childItem: NavigationItem,
  ): void {
    this.goPage(childItem.routeLink)
  }

  changeActiveIndex(historyParams?: Location): void {
    const pathname = historyParams
      ? historyParams.pathname
      : this.props.location.pathname
    const initActiveIndex = this.state.navigationList.findIndex(
      (firstLevelItem) => {
        return firstLevelItem.routeLink === pathname
      },
    )
    if (pathname == routePath.OVERVIEW_INTRO) {
      this.setState({
        activeIndex: -1,
        activeChildIndex: -1,
        clickedIndex: -1,
        showChild: true,
      })
    }

    if (pathname.includes(routePath.INTRO_VIDEOS)) {
      this.setState({
        activeIndex: 2,
        activeChildIndex: -1,
        clickedIndex: 2,
        showChild: true,
      })
      return
    }
    if (initActiveIndex > -1) {
      this.setState({
        activeIndex: initActiveIndex,
        clickedIndex: initActiveIndex,
      })
      return
    }
    this.state.navigationList.forEach((firstLevelItem) => {
      const childrenList = firstLevelItem.children
      if (childrenList.length > 0) {
        childrenList.forEach((childItem) => {
          if (pathname.includes(childItem.routeLink)) {
            this.setState({
              activeIndex: firstLevelItem.index,
              activeChildIndex: childItem.index,
              clickedIndex: firstLevelItem.index,
              showChild: true,
            })
          }
        })
      }
    })
  }

  /***************************************************************************
   * lifecycle methods
   **************************************************************************/

  componentDidMount(): void {
    this.changeActiveIndex()
    document.addEventListener('click', (e) => {
      e.stopImmediatePropagation()
      this.setState({
        showNavigation: false,
      })
    })
    this.props.history.listen((historyParams) => {
      this.changeActiveIndex(historyParams)
      this.setState({
        pcShowChild: false,
      })
    })
  }

  // componentWillUnmount() {}

  /* rarely used lifecycle methods */

  // static getDerivedStateFromProps(props, state) {}

  // shouldComponentUpdate(nextProps, nextState) {}

  // getSnapshotBeforeUpdate(prevProps, prevState) {}

  /* error boundaries */

  // static getDerivedStateFromError(error) {}

  // componentDidCatch(error, info) {}

  /* legacy lifecycle methods */

  // UNSAFE_componentWillMount() {}

  // UNSAFE_componentWillReceiveProps(nextProps) {}

  // UNSAFE_componentWillUpdate(nextProps, nextState) {}
}

export default withRouter(TopNavigation)
