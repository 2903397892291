/**
 *
 *
 * @author fuyg
 * @date  2020-06-08
 */
import { axios, API_PREFIX } from 'src/http'
import { buildUrl } from 'src/utils/urlSearch'
import {
  RawInvestorEvent,
  InvestorEvent,
  parseInvestorEvent,
} from './recentInvestorEvents'

const theApi = `${API_PREFIX}/pub/report/details`

export interface ReportDetailRequestParams {
  id: string
}

async function getReportDetail(
  params: ReportDetailRequestParams,
): Promise<InvestorEvent> {
  const url = buildUrl(theApi, {
    id: params.id,
  })
  const response = await axios.get(url)
  const parsed = parseInvestorEvent(response.data.data as RawInvestorEvent)
  return parsed
}

export { getReportDetail }
