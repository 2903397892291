/**
 *
 *
 * @author yangxin
 * @date  2020-06-19
 */
function scrollToTop(): void {
  document.body.scrollIntoView()
}

export { scrollToTop }
