/**
 *
 * @author yangxin
 * @date  2020-07-07
 */

function fixNumber(value: number, digits = 2): string {
  const multiplier = Math.pow(10, digits)
  const num = Math.round(value * multiplier) / multiplier
  return num.toFixed(digits)
}

export { fixNumber }
