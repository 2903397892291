/**
 *
 * @author fuyg
 * @date  2020-06-03
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './CalendarView.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { EventCalendarArticle } from 'src/pages/home/components/EventCalendarArticle'

function CalendarView(): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  // dispatch(fetchData({}))
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  return (
    <div className={styles.container}>
      <EventCalendarArticle />
    </div>
  )
}

export default CalendarView
