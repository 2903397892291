/**
 *
 * @author fuyg
 * @date  2020-05-26
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/pages/home/store'
import {
  StockResponseData,
  StockItemData,
  RequestParams,
  getStock,
  getStockList,
} from 'src/pages/home/apis/stock'

interface StockState {
  loading: boolean
  error: string
  stock: StockResponseData | null
  stockList: StockItemData[]
}

const initialState: StockState = {
  loading: false,
  error: '',
  stock: null,
  stockList: [],
}

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    getStockStart(state): void {
      state.loading = true
    },
    getStockSuccess(state, action: PayloadAction<StockResponseData>): void {
      state.loading = false
      state.stock = action.payload
    },
    getStockListSuccess(state, action: PayloadAction<StockItemData[]>): void {
      state.loading = false
      state.stockList = action.payload
    },
    getStockError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getStockStart,
  getStockSuccess,
  getStockListSuccess,
  getStockError,
} = stockSlice.actions

export default stockSlice.reducer

export const fetchStock = (params?: RequestParams): AppThunk => async (
  dispatch,
): Promise<void> => {
  try {
    dispatch(getStockStart())
    if (!params) {
      // 获取最新的股价信息
      const data = await getStock()
      dispatch(getStockSuccess(data))
    } else {
      // 获取历史股价列表数据
      const data = await getStockList(params)
      dispatch(getStockListSuccess(data.data))
    }
  } catch (err) {
    dispatch(getStockError(err as string))
  }
}
