/**
 *
 * @author fuyg
 * @date  2020-06-01
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InvestorEvent,
  RequestParams,
  getRecentInvestorEvents,
} from 'src/pages/home/apis/recentInvestorEvents'

import { AppThunk } from 'src/pages/home/store'

interface RecentInvestorEventsState {
  loading: boolean
  error: string
  events: InvestorEvent[]
}

const initialState: RecentInvestorEventsState = {
  loading: false,
  error: '',
  events: [],
}

const slice = createSlice({
  name: 'recentInvestorEvents',
  initialState,
  reducers: {
    getEventsStart(state): void {
      state.loading = true
    },
    getEventsSuccess(state, action: PayloadAction<InvestorEvent[]>): void {
      state.loading = false
      state.events = action.payload
    },
    getEventsError(state, action: PayloadAction<string>): void {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  getEventsStart,
  getEventsError,
  getEventsSuccess,
} = slice.actions

export default slice.reducer

export const fetchRecentInvestorEvents = (params?: RequestParams): AppThunk => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch(getEventsStart())
      const data = await getRecentInvestorEvents(params)
      dispatch(getEventsSuccess(data))
    } catch (err) {
      dispatch(getEventsError(err as string))
    }
  }
}
