/**
 *
 * @author fuyg
 * @date  2020-06-10
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './FinancialView.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'
import { SideNavigation, NavItem } from 'src/components/SideNavigation'
import shortid from 'shortid'
import routePath from 'src/pages/home/router/routePath'
import { navText } from 'src/utils/navText'

export interface FinancialViewProps {
  propName: string
}

function FinancialView(props: RouteConfigComponentProps): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  // dispatch(fetchData({}))
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  const navList: NavItem[] = [
    {
      id: shortid.generate(),
      name: navText.quanterlyEarnings,
      routeLink: routePath.FINANCIALS_QUANTERLY_EARNINGS,
    },
    // 暂时注释，不要删除
    // {
    // id: shortid.generate(),
    // name: navText.operating,
    // routeLink: routePath.FINANCIALS_DATA,
    // },
    {
      id: shortid.generate(),
      name: navText.annualReports,
      routeLink: routePath.FINANCIALS_ANNUAL_REPORT,
    },
    {
      id: shortid.generate(),
      name: navText.secFilings,
      routeLink: routePath.FINANCIALS_SEC,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <SideNavigation navList={navList} />
      </div>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default FinancialView
