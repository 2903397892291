/**
 *
 * @author yangxin
 * @date  2020-05-26
 */

import React, { ReactElement } from 'react'
import styles from './BottomBar.module.scss'
import { ToolBar } from 'src/components/ToolBar'
import classnames from 'classnames'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BottomBarProps {
  // propName: string
  showToolBar: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BottomBarState {
  stateName: string
  curYear: number
}

class BottomBar extends React.Component<BottomBarProps, BottomBarState> {
  /***************************************************************************
   *  static properties and methods
   **************************************************************************/

  static defaultProps = {
    // TODO
  }

  constructor(props: BottomBarProps) {
    super(props)

    this.state = {
      stateName: 'TODO',
      curYear: new Date().getFullYear(),
    }

    // bind this
    this.nameMethod = this.nameMethod.bind(this)
  }

  render(): ReactElement {
    return (
      <div
        className={classnames(
          styles.container,
          this.props.showToolBar ? '' : styles.withoutTool,
        )}
      >
        {this.props.showToolBar ? <ToolBar /> : ''}
        Copyright {this.state.curYear}, Youdao, Inc. All Rights Reserved.
      </div>
    )
  }

  /***************************************************************************
   *  custom methods
   **************************************************************************/

  nameMethod(): void {
    // TODO
  }

  /***************************************************************************
   * lifecycle methods
   **************************************************************************/

  // componentDidMount() {}

  // componentDidUpdate(prevProps, prevState, snapshot) {}

  // componentWillUnmount() {}

  /* rarely used lifecycle methods */

  // static getDerivedStateFromProps(props, state) {}

  // shouldComponentUpdate(nextProps, nextState) {}

  // getSnapshotBeforeUpdate(prevProps, prevState) {}

  /* error boundaries */

  // static getDerivedStateFromError(error) {}

  // componentDidCatch(error, info) {}

  /* legacy lifecycle methods */

  // UNSAFE_componentWillMount() {}

  // UNSAFE_componentWillReceiveProps(nextProps) {}

  // UNSAFE_componentWillUpdate(nextProps, nextState) {}
}

export default BottomBar
