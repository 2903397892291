/**
 *
 * @author yangxin
 * @date  2020-06-05
 */

import React, { ReactElement /*, useEffect */ } from 'react'
// import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import styles from './Resources.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'
import routePath from 'src/pages/home/router/routePath'
import shortid from 'shortid'
import { navText } from 'src/utils/navText'

import { SideNavigation } from 'src/components/SideNavigation'

function Resources(props: RouteConfigComponentProps): ReactElement {
  const navList = [
    // {
    //   id: shortid.generate(),
    //   name: navText.businessIntroVideos,
    //   routeLink: routePath.RESOURCE_INTRO_VIDEOS,
    // },
    {
      id: shortid.generate(),
      name: navText.irContacts,
      routeLink: routePath.RESOURCE_CONTACT,
    },
    {
      id: shortid.generate(),
      name: navText.emailAlerts,
      routeLink: routePath.RESOURCE_EMAIL,
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <SideNavigation navList={navList} />
      </div>
      <div className={styles.content}>{renderRoutes(props.route?.routes)}</div>
    </div>
  )
}

export default Resources
