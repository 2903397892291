/**
 *
 *
 * @author fuyg
 * @date  2020-06-10
 */
import { axios, API_PREFIX } from 'src/http'
import shortid from 'shortid'

const theApi = `${API_PREFIX}/pub/financialSummary`

interface RawFinancialSummary {
  cashAndCashEquivalentAtEndOfYear: string
  cashAndCashEquivalentsAtBeginningOfYear: string
  cashFlowsFromOperatingActivities: string
  grossProfitsMargin: string
  grossProfitsMarginLearningService: string
  grossProfitsMarginOnlineMarketingServices: string
  grossProfitsmarginLearningProducts: string
  name: string
  netRevenue: string
  netRevenueLearningProducts: string
  netRevenueLearningService: string
  netRevenueOnlineMarketingServices: string
  ol: string
  operatingExpenses: string
  operatingExpensesGeneralAndAdministrativeExpenses: string
  operatingExpensesResearchAndDevelopmentExpenses: string
  operatingExpensesSellingAndMarketingExpenses: string
  operatingLoss: string
}

export interface FinancialSummary extends RawFinancialSummary {
  shortId: string
}

function parseResponseData(data: RawFinancialSummary[]): FinancialSummary[] {
  if (!data || !data.length) {
    return []
  }
  return data.map((item) => {
    return {
      ...item,
      shortId: shortid.generate(),
    }
  })
}

async function getFinancialSummary(): Promise<FinancialSummary[]> {
  const url = theApi
  const response = await axios.get(url)
  const parsed = parseResponseData(response.data.data as RawFinancialSummary[])
  return parsed
}

export { getFinancialSummary }
