/**
 *
 * @author wangjm
 * @date  2020-06-18
 */

import React, { ReactElement /*useEffect, useState */ } from 'react'
// import { /*useSelector,*/ useDispatch /*shallowEqual*/ } from 'react-redux'
import styles from './CorporateGovernance.module.scss'
// import { fetchData } from '../sliceName'
// import { RootState } from 'src/pages/home/store/rootReducer'
import { DownloadButton } from 'src/pages/home/components/DownloadButton'
import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'

function CorporateGovernance(): ReactElement {
  // 获取数据
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(fetchData())
  // }, [])

  // 绑定数据
  // const { data } = useSelector((state: RootState) => {
  // return {
  // data: state.sliceName.data,
  // }
  // }, shallowEqual)

  const fileData = [
    {
      title: 'Audit Committee Charter',
      fileUrl:
        'https://download.ydstatic.com/zhiyun/Youdao - Audit Committee Charter.pdf',
    },
    {
      title: 'Compensation Committee Charter',
      fileUrl:
        'https://download.ydstatic.com/zhiyun/Youdao - Compensation Committee Charter.pdf',
    },
    {
      title: 'Nominating Committee Charter',
      fileUrl:
        'https://download.ydstatic.com/zhiyun/Youdao - Nominating and Corporate Governance Committee Charter.pdf',
    },
    {
      title: 'Code of Business Conduct and Ethics',
      fileUrl:
        'https://download.ydstatic.com/zhiyun/Code of Business Conduct and Ethics-IR.pdf',
    },
  ]
  return (
    <div className={styles.container}>
      <BigTitle title={navText.corporateGovernance} />
      <p>
        Youdao is committed to upholding the highest standards of business
        ethics and conducts business in accordance with all applicable laws,
        rules and regulations. Our corporate governance policies are designed to
        protect the interests of our shareholders and promote responsible
        business practices and corporate citizenship. To view our Code of
        Business Conduct and Ethics and other related Corporate Governance
        documents please refer to the links on this page.
      </p>
      <div className={styles.content}>
        {fileData.map((item /*, index*/) => {
          return (
            <div className={styles.itemContainer} key={item.title}>
              <h3 className={styles.title}>{item.title}</h3>
              <DownloadButton
                url={item.fileUrl}
                downloadName={item.title}
                text="Download"
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CorporateGovernance
