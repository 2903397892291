/**
 *
 *
 * @author yangxin
 * @date  2020-07-31
 */

function isMobile(): boolean {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  )

  if (!flag) {
    return false
  }

  return true
}

function isAndroid(): boolean {
  const flag = navigator.userAgent.match(/Android/i)

  if (!flag) {
    return false
  }

  return true
}

export { isMobile, isAndroid }
