/**
 * @author bjhuangdy
 * @date  2021-02-07
 */

import React, { ReactElement } from 'react'
import styles from './FinancialReleases.module.scss'
import { NewsSummary } from '../../apis/news'
import { RecentNewsItem } from '../RecentNewsItem'

export interface FinancialReleasesProps {
  RLnews: NewsSummary[]
}
function FinancialReleases(props: FinancialReleasesProps): ReactElement {
  const { RLnews } = props
  return (
    <div className={styles.container}>
      <p className={styles.title}>Financial Releases</p>
      <div
        className={`${styles.content} ${
          RLnews.length > 0 ? null : styles.empty
        }`}
      >
        {RLnews.length > 0
          ? RLnews.map((item) => {
              return (
                <RecentNewsItem
                  key={item.shortId}
                  news={item}
                  styleFixed={true}
                />
              )
            })
          : 'Not available now'}
      </div>
    </div>
  )
}

export default FinancialReleases
