/**
 *
 * @author yangxin
 * @date  2020-06-11
 */
import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import moment from 'moment'
import { fetchAppearYears } from 'src/pages/home/store/slices/filingsAppearYears'
import { fetchSECFilings } from 'src/pages/home/store/slices/SECFilings'
import { RootState } from 'src/pages/home/store/rootReducer'
import styles from './FilingsDisplay.module.scss'

import { FilingsTable } from 'src/pages/home/components/FilingsTable'
import { Select } from 'antd'
const { Option } = Select

const TRAILING_MONTHS = 'Trailing 12-Months'
function FilingsDisplay(): ReactElement {
  const [year, setYear] = useState('')
  const [pageIndex, setPageIndex] = useState(1)

  function getDateByYear(
    year: string,
  ): {
    startDate: string
    endDate: string
  } {
    const isTrailing = year === TRAILING_MONTHS
    const endStamp = isTrailing
      ? moment().endOf('day')
      : moment().year(Number(year)).endOf('year')

    const startStamp = isTrailing
      ? moment().endOf('day').subtract(1, 'year')
      : moment().year(Number(year)).endOf('year').subtract(1, 'year')
    return {
      startDate: startStamp.format('YYYY-MM-DD'),
      endDate: endStamp.format('YYYY-MM-DD'),
    }
  }
  // 获取数据
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAppearYears())
  }, [])

  useEffect(() => {
    if (year) {
      const { startDate, endDate } = getDateByYear(year)
      dispatch(
        fetchSECFilings({
          startDate,
          endDate,
          page: 1,
        }),
      )
      setPageIndex(1)
    }
  }, [year])

  // 绑定数据
  const { years, filingsData, totalCount } = useSelector((state: RootState) => {
    const yearList = state.filingsAppearYears.appearYears.slice()
    yearList.unshift(TRAILING_MONTHS)
    return {
      years: yearList,
      filingsData: state.SECFilings.list,
      totalCount: state.SECFilings.totalCount,
    }
  }, shallowEqual)

  function handleChange(value: string): void {
    setYear(value)
  }

  function handlePageIndexChange(newPageIndex: number): void {
    const { startDate, endDate } = getDateByYear(year)
    dispatch(
      fetchSECFilings({
        startDate,
        endDate,
        page: newPageIndex,
      }),
    )
    setPageIndex(newPageIndex)
  }

  if (!year && years && years.length > 0) {
    setYear(years[0])
  }

  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <div className={styles.selectItem}>
          <span className={styles.label}>Year</span>
          <Select
            key={years && years[0] ? years[0] : ''}
            defaultValue={years && years[0] ? years[0] : ''}
            style={{ width: 120 }}
            onChange={handleChange}
            loading={!years || !years.length ? true : false}
          >
            {years.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              )
            })}
          </Select>
        </div>
      </div>

      <FilingsTable
        filingsData={filingsData}
        totalCount={totalCount}
        pageIndexChange={handlePageIndexChange}
        pageIndex={pageIndex}
      />
    </div>
  )
}

export default FilingsDisplay
