/**
 *
 * @author fuyg
 * @date  2020-05-26
 */

import React, { ReactElement } from 'react'
import { RoundLightButton } from 'src/components/RoundLightButton'
import { ReactComponent as Icon } from './supplemental.svg'
// import styles from './SupplementalButton.module.scss'
import { useHistory } from 'react-router-dom'
import routePath from 'src/pages/home/router/routePath'
// import { buildRoutePath } from 'src/utils/buildRoutePath'

interface SupplementalButtonProps {
  reportId: string
}

function SupplementalButton(): ReactElement {
  // const { reportId } = props
  const history = useHistory()
  const onClick = (): void => {
    const path = routePath.REPORT_INTRO_VIDEOS
    history.push(path)
  }
  return <RoundLightButton icon={Icon} text="Videos" onClick={onClick} />
}

export default SupplementalButton
