import React, { ReactElement } from 'react'
import styles from './BusinessOverview.module.scss'

import { BigTitle } from 'src/pages/home/components/BigTitle'
import { navText } from 'src/utils/navText'
import business from './business.png'

function BusinessOverview(): ReactElement {
  return (
    <div className={styles.container}>
      <span className={styles.content}>
        <BigTitle title={navText.businessOverview} />
        <div className={styles.mainContent}>
          <p>
            We are the leading intelligent learning company in China with over
            100.0 million average total monthly active users (“MAUs”) in the
            first half of 2019¹. Through technology, we enrich the lives of
            people of all ages every day, guiding them on their journey of
            pursuing knowledge and sharing ideas. Starting from online knowledge
            tools, we currently offer a comprehensive suite of learning products
            and services that are accessible, reliable and trustworthy.
          </p>
          <p>
            Our products and services are built upon a common set of core
            technologies, which allows us to use data insights gained from
            individual product or service to help optimize our entire product
            and service portfolio. Our business has evolved significantly since
            inception and we’ve never stopped re-imagining and innovating our
            products and services. We’re doing this not only to cater to, but
            influence, the learning habits and lifestyles of our users, to
            fulfill their goals and enrich their lives. Since our inception, our
            apps have amassed over 1.3 billion cumulative downloads and more
            than 200 million student enrollments. Fueling all of these great
            achievements are our technologies. That’s why we’ll continue to
            invest in technology and products for our users, and for our
            long-term success.
          </p>
          <p>
            The early success of our first major product Youdao Dictionary has
            enabled us to attract a massive user base, build a strong brand, and
            expand into a broad range of products and services addressing
            lifelong learning needs of pre-school, K-12 and college students as
            well as adult learners, including:
          </p>
          <p className={styles.imgContainer}>
            <img src={business} />
          </p>
          <p>
            <span className={styles.textTitle}>Online Knowledge Tools</span>
            <br />
            Youdao provides a collection of online dictionary, translation and
            writing tools, such as our flagship product Youdao Dictionary,
            Youdao Translation, and Youdao Cloudnote. Powered by leading
            technologies, our tools are convenient, smart and powerful. These
            tools have helped drive organic user traffic to our online courses
            and other products and services.
          </p>
          <p>
            <span className={styles.textTitle}>Online Courses</span>
            <br />
            Building on the popularity of the online knowledge tools, Youdao
            offers online courses, including Youdao Premium Courses, our
            flagship online course brand, with a strategic focus on K-12
            students, as well as NetEase Cloud Classroom and China University
            MOOC for adult users. The online courses cover a wide spectrum of
            age groups, subject matters, learning goals and areas of interest.
          </p>
          <p>
            <span className={styles.textTitle}>Interactive Learning Apps</span>
            <br />
            Youdao also offers a variety of interactive learning apps, such as
            Youdao Math and Youdao Speaking, that enable students to study math,
            English and other subjects with a virtual teacher on their mobile
            devices. These interactive learning apps provide an abundance of
            gamified features that help significantly increase younger students’
            interest levels and drive their engagement.
          </p>
          <p>
            <span className={styles.textTitle}>Smart Devices</span>
            <br />
            Youdao has launched its line of smart devices, such as Youdao Smart
            Pen, Youdao Dictionary Pen and Youdao Pocket Translator, that
            further enhance users’ learning experience and efficiency. The
            approach to such devices is the seamless integration of AI
            algorithms and data processing into hardware devices that supplement
            Youdao’s online knowledge tools and online courses, bridging the gap
            between traditional pen-and-paper-based learning and intelligent
            learning.
          </p>
          <p>
            Note 1: “Average total MAUs” refers to the monthly average of the
            sum of Youdao’s total MAUs, which is calculated by combining the
            MAUs of various products and services except for smart devices for
            that month. The duplicate access to different products and services
            is not eliminated from the calculation.
          </p>
        </div>
      </span>
    </div>
  )
}

export default BusinessOverview
