/**
 *
 * @author fuyg
 * @date  2020-05-20
 */

import React, { ReactElement } from 'react'
import { Carousel } from 'antd'
import styles from './Banner.module.scss'
import Slogan from './Slogan'
import StockMarket from './StockMarket'

function Banner(): ReactElement {
  const bannerKeys = ['hardware', 'chess', 'adult-edu', 'edu']
  const bannerNames = [
    'Smart Devices',
    'STEAM Courses',
    'Adult and Vocational Education',
    'Education Digitalization Solution ',
  ]

  return (
    <Carousel autoplay dots={{ className: 'banner-dots' }}>
      {bannerKeys.map((key: string, i: number) => (
        <div
          key={key}
          className={`${styles.container} ${styles[key]} ${styles['banner-bg']}`}
        >
          <div className={styles.layer}></div>
          <div className={styles.center}>
            <Slogan />
            <div className={styles.stock}>
              <StockMarket />
            </div>
            <p className={styles['banner-name']}>{bannerNames[i]}</p>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default Banner
