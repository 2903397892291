/**
 *
 * @author fuyg
 * @date  2020-06-02
 */
import moment, { Moment } from 'moment'
import 'moment-timezone'

// 参考 https://stackoverflow.com/questions/20966042/how-to-convert-utc-date-time-to-est-date-time
function toNewYorkEst(time: number | Moment): string {
  const date = moment(time)
  // "Wednesday, February 26, 2020, 8:00pm EST"
  return date.tz('America/New_York').format('dddd, MMMM D, YYYY HH:mm z')
}

function toNewYorkSimple(time: number | Moment): string {
  const date = moment(time)
  // "Feb 27, 2020"
  return date.tz('America/New_York').format('MMM D, YYYY')
}

function toNewYorkMonth(time: Moment): string {
  const date = moment(time)
  // "Feb 2020"
  return date.tz('America/New_York').format('MMM YYYY')
}

function toNewYorkDay(time: number | Moment): string {
  const date = moment(time)
  // "27-Feb-2020"
  return date.tz('America/New_York').format('D-MMM-YY')
}

export { toNewYorkEst, toNewYorkSimple, toNewYorkMonth, toNewYorkDay }
